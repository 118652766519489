const API_URL = "https://open-theta.de/api/";
// const API_URL = " http://localhost:80/api/";
const ABI_NFT = [
    "function balanceOf(address _owner) view returns (uint256)",
    "function tokenURI(uint256 _tokenId) view returns (string memory)",
    "function tokenOfOwnerByIndex(address _owner, uint256 _index) view returns (uint256)",
    "function approve(address to, uint256 tokenID)",
    "function getApproved(uint256 tokenId) view returns (address)",
    "event Approval(address indexed owner, address indexed to, uint256 tokenId)",
    "event Transfer(address indexed src, address indexed dst, uint256 tokenId)",

    "function MAX_NFT_SUPPLY() view returns (uint256)",
    "function totalSupply() view returns (uint256)",
    "function saleIsActive() view returns (bool)",
    "function getNFTPrice() view returns (uint256)",
    "function safeMint(address _address) payable",
    "function transferFrom(address from, address to, uint256 tokenId)",
    "function safeMintQuantity(address to, uint8 quantity) payable",
    "function getDiscountedNFTPrice(address buyer) view returns (uint256)",
    "function numberOfDiscounts() view returns (uint16)"
];

const ABI_MARKET = [
    "event MarketItemCreated(uint256 indexed itemId, address indexed nftContract, uint256 indexed tokenId, address seller, address owner, string category, uint256 price, bool isSold)",
    "function createMarketItem(address nftContract, uint256 tokenId, uint256 price, string category) payable",
    "function createMarketSale(address nftContract, uint256 itemId) payable",
    "function createMarketCancel(address nftContract, uint256 itemId) payable",
    "function updateMarketItem(address nftContract, uint256 tokenId, uint256 price, uint256 itemId)",
    "function fetchCreateNFTs() view returns (tuple(uint256 itemId, address nftContract, uint256 tokenId, address seller, address owner, string category, uint256 price, bool isSold)[])",
    "function fetchPurchasedNFTs() view returns (tuple(uint256 itemId, address nftContract, uint256 tokenId, address seller, address owner, string category, uint256 price, bool isSold)[])",
    "function getItemsByCategory(string category) view returns (tuple(uint256 itemId, address nftContract, uint256 tokenId, address seller, address owner, string category, uint256 price, bool isSold)[])",
    "function getListingPrice() view returns (uint256)",
    "function getMarketItems() view returns (tuple(uint256 itemId, address nftContract, uint256 tokenId, address seller, address owner, string category, uint256 price, bool isSold)[])",
    "function getSalesFee() view returns (uint256)",
    "function getCreatorFeeBasisPoints(address NFTAddress) view returns (address, uint256)",
    "event MarketItemSale(uint256 itemId,address nftContract,uint256 tokenId,address seller,address owner,string category,uint256 price,bool isSold)"
];

const MARKET_ADDRESS = '0xbb5f35d40132a0478f6aa91e79962e9f752167ea';
const MARKET_ADDRESS_OLD = '0xd539558887b6744c52c595cb24fb9efa664ba814';
export default API_URL;
export {ABI_NFT, ABI_MARKET, MARKET_ADDRESS, MARKET_ADDRESS_OLD};
