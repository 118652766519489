<template>
  <div class="ExploreArt">
    <div class="TitleAndFilter">
      <h2>Mint New NFTs</h2>
    </div>
      <div class="ExploreArt__Card">
        <div v-for="(project, n) in launchProjects" :key="n">
          <LaunchCard :project="project" v-if="n < loadNumber"></LaunchCard>
        </div>
      </div>
    <div v-if="loadNumber < totalProjectNumber"
         style="justify-content: center; display: flex; margin-top: 50px">
      <button id="LoadButton" @click="loadMore('NEXT')">Load More</button>
    </div>
    <div v-if="loadNumber < totalProjectNumber"
         style="justify-content: center; display: flex; margin-top: 50px">
      <a @click="loadMore('ALL')" style="color: lightgray">Load All</a>
    </div>
    </div>

</template>

<script>

import LaunchCard from "@/components/LaunchCard";
import axios from "axios";
import API_URL from "../../Config";
// import projects from '../promotion/launchingProjects.json'


export default {
  name: "LaunchProjects",
  data() {
    return {
      launchProjects:[],
      currentSupply: 0,
      loadNumber: 6,
      totalProjectNumber: 0,
    };
  },
  components: {
    LaunchCard
  },
  methods: {
    loadMore(loading) {
      if (loading === 'ALL') {
        this.loadNumber = this.totalProjectNumber
      } else if (loading === 'NEXT') {
        this.loadNumber += 6
      }
    },
  },
  mounted() {
    // console.log(API_URL);
    axios.get(`${API_URL}static/launchingProjects.json`).then((response) => {
      this.totalProjectNumber = response.data.length
      this.launchProjects = response.data;
    });
  },
};
</script>



