<template>
  <div class="Card">
    <img v-lazy="auction.imgUrl" alt="" class="NFTImg" @click="SwitchToDetailArt(nft)" />
    <div class="AvatarName" style="margin: 0px; padding: 5px 0px">
      <img
          v-lazy="auction.creatorImgUrl"
          alt=""
          style="width: 40px; height: 40px; border-radius: 100%"
      />
      <span>{{ auction.creatorName }}</span>
    </div>
    <h3>{{ auction.name }}</h3>
    <h5 v-if="countdownTime && forSale" style="padding-bottom: 5px; color: white" class="timer" v-bind:style="{ color: activeColor}">{{countdownTime}}</h5>
    <div class="Price" style="color: #FFFFFF; display: inline">
      <h6 v-if="!isOver && forSale" style=" display: inline; padding-right: 5px">Highest Bid:</h6>
      <h6 v-else-if="!forSale" style=" display: inline; padding-right: 5px">Minimum Bid:</h6>
      <h6 v-if="isOver" style=" display: inline; padding-right: 5px">Winning Bid:</h6>
      <h6 style="display: inline">{{ (highestBid).toLocaleString('en').replace(',', ' ').replace(',', ' ')}} <img src="./../assets/Img/tfuel_token.png" alt="" style="width: 25px; border-radius: 100%; padding-left: 3px"/></h6>
    </div>

    <p v-if="myNFT" style="padding: 5px 0px; color: #FFFFFF">You have the highest bid</p>
    <p v-else-if="forSale" style="padding: 5px 0px; color: #FFFFFF">Someone else has the highest bid</p>
    <p v-else style="padding: 5px 0px; color: #FFFFFF">Starting at: {{new Date(startTimestamp*1000).toLocaleString()}}</p>

<!--    <div>-->
<!--      <span style="font-size: 12px">Editions: {{ auction.editions }}</span>-->
<!--      <span style="font-size: 12px; padding-left: 5px" v-if="!/#\d/.test(nft.name) && nft.name.length > 20">TokenID: {{ nft.tokenId }}</span>-->
<!--    </div>-->

    <div class="BuyAndPrice InputField" style="justify-content: space-between" v-if="forSale && !isOver">
      <button @click="BidFunction()" style="margin-right: 10px">Bid</button>
      <input v-model="bid" placeholder="price ..." step=".01" type="number" @keypress="PriceFunction">
<!--      <img alt="" src="./../assets/Img/tfuel_token.png" style="width: 25px; border-radius: 100%; padding-left: 0px"/>-->
    </div>


    <Loading :active.sync="loading"
             color="#18E5E7"
             background-color="#000"
             loader="dots"
    ></Loading>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import {ethers} from "ethers";

export default {
  name: "ActionCard",
  props: ['auction'],
  data () {
    return {
      highestBid: '0',
      loading: false,
      myNFT: false,
      bid: null,
      activeColor: "#ffffff",
      countdownTimer:null,
      endTimestamp: 0,
      startTimestamp: 0,
      countdownTime: null,
      forSale: false,
      isOver: false,
    }
  },
  components: {
    Loading
  },
  methods: {
    async BidFunction() {
      this.loading = true;
      let provider = this.$store.state.Provider;
      const signer = provider.getSigner();


      const contractAuctionObject = new ethers.Contract(
          this.auction.auctionContract,
          ["function placeBid(uint256 itemId, address nftContract, uint256 bid) payable"],
          signer
      );
      let bid = ethers.BigNumber.from(this.bid * 100).mul(ethers.BigNumber.from("10000000000000000"));

      let overrides = {
        value: bid,
      };
      try{
        await contractAuctionObject.placeBid(this.auction.itemId, this.auction.nftContract, bid, overrides);
      } catch (e) {
        this.loading = false;
      }
      let topic = ethers.utils.id("BidPlaced(uint256,address,uint256,address,uint256)");

      let filter = {
        address: this.auction.auctionContract,
        topics: [ topic ]
      }

      provider.on(filter, () => {
        this.loading = false;
        this.updateData();
        console.log("found")
      });
    },

    PriceFunction($event) {
      // console.log($event.keyCode); //keyCodes value
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);

      // only allow number and one dot
      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || this.bid.indexOf('.') != -1)) { // 46 is dot
        $event.preventDefault();
      }

      // restrict to 2 decimal places
      if (this.bid != null && this.bid.indexOf(".") > -1 && (this.bid.split('.')[1].length > 1)) {
        $event.preventDefault();
      }
    },
    pad(n) {
      return (n < 10 ? "0" + n : n);
    },
    timer() {
      // let endTime = new Date(this.endDate).getTime();
      let startTime = Date.now() / 1000
      let seconds = (this.endTimestamp - startTime)

      if(this.startTimestamp < startTime) this.forSale = true;
      if(this.endTimestamp < startTime) {
        this.isOver = true;
        return;
      }

      // console.log(seconds)
      if(seconds <= 0) {
        clearInterval(this.countdownTimer);
      } else {
        let days        = Math.floor(seconds/24/60/60);
        let hoursLeft   = Math.floor((seconds) - (days*86400));
        let hours       = Math.floor(hoursLeft/3600);
        let minutesLeft = Math.floor((hoursLeft) - (hours*3600));
        let minutes     = Math.floor(minutesLeft/60);
        let remainingSeconds = seconds % 60;
        try{
          if(days === 0){
            this.countdownTime = this.pad(hours) + "h " + this.pad(minutes) + "m " + this.pad(parseInt(remainingSeconds)) + "s";
            if(hours < 1){
              this.activeColor = "#fd3232";
            }
          } else {
            this.countdownTime = this.pad(days) + "d " + this.pad(hours) + "h " + this.pad(minutes) + "m " + this.pad(parseInt(remainingSeconds)) + "s";
          }
        } catch{
          clearInterval(this.countdownTimer);
        }
      }
      // console.log(endTime, startTime)
    },
    countdown () {
      this.countdownTimer = setInterval(this.timer, 1000);
      this.timer()
    },
    updateData(){
      let provider = this.$store.state.Provider;
      const signer = provider.getSigner();


      const contractAuctionObject = new ethers.Contract(
          this.auction.auctionContract,
          ["function getByAuctionId(uint256 id) view returns (tuple(uint256 itemId, address nftContract, uint256 tokenId, uint256 minBid, uint256 startTimestamp, uint256 endTimestamp, address highestBidderAddress, uint256 highestBid, bool sold, address creatorAddress, uint creatorBasePoints))"],
          signer
      );

      contractAuctionObject.getByAuctionId(this.auction.itemId).then(res => {
        // console.log(res)
        if(this.auction.nftContract !== res.nftContract){
          console.log("error")
        }
        this.highestBid = (ethers.BigNumber.from(res.highestBid).div(ethers.BigNumber.from("10000000000000000"))).toNumber() / 100;


        let userAddress = this.$store.state.UserAddress
        if(res.highestBidderAddress.toLowerCase() === userAddress.toLowerCase()) {
          this.myNFT = true
        }
        // console.log(this.myNFT)
      });
    }
  },

  mounted() {
    let provider = this.$store.state.Provider;
    let userAddress = this.$store.state.UserAddress
    if(userAddress === ""){
      window.ethereum.request({ method: 'eth_requestAccounts' }).then(accounts => {
        userAddress = accounts[0];
        try {
          const signer = provider.getSigner();
          const contractAuctionObject = new ethers.Contract(
              this.auction.auctionContract,
              ["function getByAuctionId(uint256 id) view returns (tuple(uint256 itemId, address nftContract, uint256 tokenId, uint256 minBid, uint256 startTimestamp, uint256 endTimestamp, address highestBidderAddress, uint256 highestBid, bool sold, address creatorAddress, uint creatorBasePoints))"],
              signer
          );

          contractAuctionObject.getByAuctionId(this.auction.itemId).then(res => {
            // console.log(res)
            if(this.auction.nftContract.toLowerCase() !== res.nftContract.toLowerCase()){
              console.log(this.auction.nftContract, res.nftContract)
              console.log("error")
            }
            this.highestBid = (ethers.BigNumber.from(res.highestBid).div(ethers.BigNumber.from("10000000000000000"))).toNumber() / 100;
            let minBid = (ethers.BigNumber.from(res.minBid).div(ethers.BigNumber.from("10000000000000000"))).toNumber() / 100
            if(minBid > this.highestBid) {
              this.highestBid = minBid
            }

            this.endTimestamp = res.endTimestamp.toNumber();
            this.startTimestamp = res.startTimestamp.toNumber();
            // console.log(new Date(this.startTimestamp*1000).toLocaleString())
            this.countdown()
            if(res.highestBidderAddress.toLowerCase() === userAddress.toLowerCase()) {
              this.myNFT = true
            }
            // console.log(this.myNFT)
          });
        } catch {
          alert("Connect your wallet");
        }
      });
    }

  }
}
</script>

<style scoped>

</style>