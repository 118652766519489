<template>
  <div class="RacebltySold__Content_Single">
    <div class="ContentImg" style="min-width:60px;padding:0px 0px 0px 5px;">
      <img :src="nft.imgUrl" alt="">
    </div>
    <div class="Content"  style="width:30%;padding:0px 10px;line-height: 10px;">
      <span style="color:white;font-size: 15px;font-family: 'Montserrat-Bold';" >{{nft.name}}</span>
    </div>
    <div class="Content RecentlySoldCreator"  style="width:25%;padding:0px 10px;line-height: 10px;">
      <span style="color:white;font-size: 15px;font-family: 'Montserrat-Bold';" >{{nft.creator}}</span>
    </div>
    <div class="Content"  style="width:30%;padding-right:10%;line-height: 10px; justify-content: flex-end; display: flex;">
      <span style="color:white;font-size: 15px;font-family: 'Montserrat-Bold';" >{{price}}<img src="./../assets/Img/tfuel_token.png" alt="" style="width: 20px; border-radius: 100%; padding-left: 5px"/></span>
    </div>
    <div class="Content"  style="width:25%;padding:0px 10px;line-height: 10px;">
      <span style="color:white;font-size: 15px;font-family: 'Montserrat-Bold';" >{{new Date(nft.soldTimestamp).toLocaleString()}}</span>
    </div>
  </div>
</template>

<script>
import {ethers} from "ethers";

export default {
  name: "recentlySoldBar",
  props: ["nft"],
  data () {
    return {
      price: ''
    }
  },
  methods: {

  },
  mounted() {
    this.price = (ethers.BigNumber.from(this.nft.price).div(ethers.BigNumber.from("10000000000000000"))).toNumber()/100;
  }
}
</script>

<style scoped>

</style>