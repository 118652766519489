<template>
  <div class="Card">
    <img :src="nft.imgUrl" alt="" class="NFTImg" @click="SwitchToDetailArt(nft)">
    <div class="AvatarName">
      <img
          v-lazy="$store.state.CreatorsToImg[this.nft.creator]"
          alt=""
          style="width: 40px; height: 40px; border-radius: 100%"
      />
      <span>{{ nft.creator }}</span>
    </div>
    <h3>{{ nft.name }}<span style="padding-left: 5px"
                            v-if="!/#\d/.test(nft.name) && nft.name.length < 21">#{{ nft.tokenId }}</span></h3>
    <p v-if="nft.marketAddress !== '0xbb5f35d40132a0478f6aa91e79962e9f752167ea'" style="color: red">OLD
      MARKETPLACE! Cancel and relist!</p>
    <span>{{ nft.price.slice(0, -18) }}<img src="./../assets/Img/tfuel_token.png" alt=""
                                            style="width: 20px; border-radius: 100%; padding-left: 5px;"/></span>
    <div v-if="update">
      <div class="BuyAndPrice InputField">
        <input v-model="price" placeholder="price ..." step=".01" type="number" @keypress="PriceFunction">
        <img alt="" src="./../assets/Img/tfuel_token.png" style="width: 25px; border-radius: 100%; padding-left: 3px"/>
      </div>
      <div class="BuyAndPrice">
        <button v-if="nft.contract !== ''" @click="UpdateFunction()">Update Price</button>
      </div>
    </div>
    <div v-if="update" class="BuyAndPrice" style="margin: 20px 0px 10px 0px;"
         @click="update = false">
      <a>Back</a>
    </div>
    <div class="BuyAndPrice" v-if="!update">
      <button @click='CancelFunction()'>Cancel</button>
      <button v-if="nft.marketAddress === '0xbb5f35d40132a0478f6aa91e79962e9f752167ea'" @click='update = true'>Update</button>
    </div>
    <Loading :active.sync="loading"
             color="#18E5E7"
             background-color="#000"
             loader="dots"
    ></Loading>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import {ABI_MARKET, MARKET_ADDRESS, MARKET_ADDRESS_OLD} from "../../Config";
import {ethers} from "ethers";
import eventBus from "@/module/eventBus";

export default {
  name: "MyNFTOnSaleCard",
  props: ['nft'],
  data() {
    return{
      userAddress: '',
      MyNFTsOnSale:[],
      loading: false,
      update: false,
      price: null
    }
  },
  components: {
    Loading,
  },
  methods:{
    SwitchToDetailArt(Explore){
      this.$store.commit('GetDetailArtData',Explore)
      this.$router.push('/DetailArt')
    },
    async CancelFunction(){
      let marketAddress = ''
      if(this.nft.marketAddress) {
        marketAddress = this.nft.marketAddress
      } else {
        marketAddress = MARKET_ADDRESS_OLD
      }
      this.loading = true;
      let provider = this.$store.state.Provider;
      const signer = provider.getSigner()
      console.log("cancel");
      const contractMarketObject = new ethers.Contract(
          marketAddress,
          ABI_MARKET,
          signer
      );

      await contractMarketObject.createMarketCancel(this.nft.nftContract, this.nft.itemId).catch((error) => {
        console.log(error);
        this.loading = false;
        eventBus.$emit('saleCanceled');
      });

      let topic = ethers.utils.id("MarketItemSale(uint256,address,uint256,address,address,string,uint256,bool)");

      let filter = {
        address: marketAddress,
        topics: [ topic ]
      }

      provider.on(filter, () => {
        this.loading = false;
        eventBus.$emit('saleCanceled');
      });
    },
    async UpdateFunction(){
      this.loading = true;
      let provider = this.$store.state.Provider;
      const signer = provider.getSigner()
      console.log("update");
      const contractMarketObject = new ethers.Contract(
          MARKET_ADDRESS,
          ABI_MARKET,
          signer
      );
      let tokenId = this.nft.tokenId
      if(tokenId === -1) tokenId = this.nft.description

      let priceWei = ethers.BigNumber.from(this.price * 100).mul(ethers.BigNumber.from("10000000000000000"));
      await contractMarketObject.updateMarketItem(this.nft.nftContract, tokenId, priceWei, this.nft.itemId).catch((error) => {
        console.log(error);
        this.loading = false;
        eventBus.$emit('saleCanceled');
      });

      let topic = ethers.utils.id("MarketItemUpdated(uint256,address,uint256,address,address,string,uint256,bool)");

      let filter = {
        address: MARKET_ADDRESS,
        topics: [ topic ]
      }

      provider.on(filter, () => {
        this.update = false;
        this.nft.price = priceWei.toString()
        this.loading = false;
        eventBus.$emit('saleCanceled');
      });
    },
    PriceFunction($event) {
      // console.log($event.keyCode); //keyCodes value
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);

      // only allow number and one dot
      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || this.price.indexOf('.') != -1)) { // 46 is dot
        $event.preventDefault();
      }

      // restrict to 2 decimal places
      if (this.price != null && this.price.indexOf(".") > -1 && (this.price.split('.')[1].length > 1)) {
        $event.preventDefault();
      }
    }
  }
}
</script>

<style scoped>

</style>