<template>
  <div class="home NTFs">
      <MyNFTSCmpt/>
      <MyNFTSOnSale/>
    <MySoldNFTs :address="userAddress" v-if="userAddress!== ''"/>
    <Myfooter/>
  </div>
</template>

<script>
import Myfooter from '../components/Footer.vue'
import MyNFTSCmpt from '../components/MyNFTs.vue'
import MyNFTSOnSale from '../components/MyNFTsOnSale.vue'
import MySoldNFTs from "@/components/MySoldNFTs";

export default {
  name: 'MyNFTs',
  data() {      
    return{
      userAddress: ''
    }
  },
  components: {
    MySoldNFTs,
     Myfooter,
     MyNFTSCmpt,
     MyNFTSOnSale
  },
  methods:{
    async connectToMetaMask (){
      if (typeof window.ethereum !== 'undefined') {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        this.userAddress = accounts[0];
        this.$store.commit('SetUserAddress', this.userAddress);
        document.getElementById('connectButton').innerHTML = this.userAddress.slice(2,5)+"..." + this.userAddress.slice(-3);
      } else {
        console.log("No MetaMask available!");
      }
    },
  },
  mounted() {
     this.userAddress = this.$store.state.UserAddress;
      if(this.userAddress === ""){
        this.connectToMetaMask();
    }
  }

}
</script>
<style scoped>
.Hero{
    background-image:url('../assets/Img/HeroBackground.png');
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
}
.StayInTheTop__content{
      background-image:url('../assets/Img/SectionBackground.png');
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
}
</style>



