<template>
  <div class="ExploreArt">
    <div class="TitleAndFilter" style="padding-top: 0px; margin-top: 50px">
      <h2>Explore Creators</h2>
    </div>
    <div class="ExploreArt__Card">
      <div v-for="(creator, n) in AllCreators" :key="n">
        <creatorCard :creator="creator" v-if="n < loadNumber"></creatorCard>
      </div>
    </div>
    <div v-if="loadNumber < totalCreatorNumber"
         style="justify-content: center; display: flex; margin-top: 50px">
      <button id="LoadButton" @click="loadMore('NEXT')">Load More</button>
    </div>
    <div v-if="loadNumber < totalCreatorNumber"
         style="justify-content: center; display: flex; margin-top: 50px">
      <a @click="loadMore('ALL')" style="color: lightgray">Load All</a>
    </div>
  </div>
</template>

<script>
import creatorCard from "@/components/creatorCard";
// import creators from "../promotion/cretors.json"
import axios from "axios";
import API_URL from "../../Config";

export default {
  name: "ExploreCreators",
  data() {
    return {
      AllCreators: [],
      loadNumber: 6,
      totalCreatorNumber: 0,
    };
  },
  components: {
    creatorCard
  },
  methods: {
    loadMore(loading) {
      if (loading === 'ALL') {
        this.loadNumber = this.totalCreatorNumber
      } else if (loading === 'NEXT') {
        this.loadNumber += 6
      }
    },
    GetAllCreators(){
      axios.get(`${API_URL}static/creators.json`).then((response) => {
        this.AllCreators = response.data;
        this.totalCreatorNumber = response.data.length
      });
    },
  },
  mounted() {
    this.GetAllCreators();
  },
};
</script>



