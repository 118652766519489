<template>
  <div v-if="comingSoon.length > 0" class="ExploreArt" style="margin-bottom: 0px!important; padding-bottom: 0px!important;">
    <div class="TitleAndFilter" style="padding: 0px; margin-top: 50px">
      <h2>Coming Soon</h2>
    </div>
    <div class="ExploreArt__Card">
      <div v-for="(project, n) in comingSoon" :key="n">
        <div class="container" style="width: 200px; height: 200px; margin: 20px">
          <a :href="project.link" target="_blank">
            <img :src="project.img" style="width: 200px!important; height: 200px!important; border-radius: 20px"/>
            <h4 v-if="project.countdown" class="top-centered">{{createDate(project.countdown)}}</h4>
            <h4 v-else class="top-centered">{{project.date}}</h4>
            <h4 v-if="project.name" class="bottom-centered">{{project.name}}</h4>
          </a>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import axios from "axios";
import API_URL from "../../Config";


export default {
  name: "LaunchProjects",
  data() {
    return {
      comingSoon:[],
    };
  },
  components: {
  },
  methods: {
    pad(n) {
      return (n < 10 ? "0" + n : n);
    },
    createDate(timestamp){
      let seconds = Math.floor((timestamp - Date.now()) /1000)
      if(seconds < 0) {
        return "launched"
      }
      let days        = Math.floor(seconds/24/60/60);
      let hoursLeft   = Math.floor((seconds) - (days*86400));
      let hours       = Math.floor(hoursLeft/3600);
      let minutesLeft = Math.floor((hoursLeft) - (hours*3600));
      let minutes     = Math.floor(minutesLeft/60);
      let timeLeft
      if(days === 0){
        timeLeft = this.pad(hours) + "h " + this.pad(minutes) + "min until launch";
      } else {
        timeLeft = this.pad(days) + "d " + this.pad(hours) + "h " + this.pad(minutes) + "min until launch";
      }
      return timeLeft
    }
  },
  mounted() {
    // console.log(API_URL)
    axios
        .get(`${API_URL}static/comingSoon.json`)
        .then(response => {
          this.comingSoon = response.data;
        });
    // axios.get(`http://localhost:80/api/static/comingSoon.json`).then((response) => {
    //   this.comingSoon = response.data;
    // });
  },
};
</script>

<style>
.container {
  position: relative;
  text-align: center;
  color: white;
}
.top-centered {
  width: 80%;
  border-radius: 5px;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #212121;
  background-color: rgba(255,255,255,0.7);
  padding: 4px;
  font-size: 12px;
}

.bottom-centered {
  width: 80%;
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #212121;
  background-color: rgba(255,255,255,0.7);
  padding: 4px;
  font-size: 12px;
}

</style>