<template>
    <div class="ExploreArt MyNFTsOnSale">
            <div class="TitleAndFilter">
                <h2>My NFTs on sale</h2>
            </div>
              <div class="ExploreArt__Card">
                    <div  v-for="nft in MyNFTsOnSale" :key="nft.itemId">
                      <MyNFTOnSaleCard :nft="nft"></MyNFTOnSaleCard>
<!--                      <div class="Card">-->
<!--                              <img :src="nft.imgUrl" alt="" class="NFTImg" @click="SwitchToDetailArt(nft)">-->
<!--                              <div class="AvatarName">-->
<!--                                <img-->
<!--                                    v-lazy="nft.projectImgUrl"-->
<!--                                    alt=""-->
<!--                                    style="width: 40px; height: 40px; border-radius: 100%"-->
<!--                                />-->
<!--                                <span>{{ nft.creator }}</span>-->
<!--                              </div>-->
<!--                              <h3>{{ nft.name }}<span style="padding-left: 5px" v-if="!/#\d/.test(nft.name) && nft.name.length < 21">#{{ nft.tokenId }}</span></h3>-->
<!--                              <p v-if="nft.marketAddress !== '0xbb5f35d40132a0478f6aa91e79962e9f752167ea'" style="color: red">OLD MARKETPLACE! Cancel and relist!</p>-->
<!--                              <span>{{nft.price.slice(0,-18)}}<img src="./../assets/Img/tfuel_token.png" alt="" style="width: 20px; border-radius: 100%; padding-left: 5px;"/></span>-->
<!--                              <div class="BuyAndPrice">-->
<!--                                    <button @click='CancelFunction(nft)'>Cancel</button>-->
<!--                              </div>-->
<!--                        </div>-->
                  </div>
                <Loading :active.sync="loading"
                         color="#18E5E7"
                         background-color="#000"
                         loader="dots"
                ></Loading>
              </div>
    </div>
</template>

<script>


import axios from "axios";
import API_URL from "../../Config";
import {ethers} from "ethers";
import {ABI_MARKET, MARKET_ADDRESS_OLD} from "../../Config";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import eventBus from "@/module/eventBus";
import MyNFTOnSaleCard from "@/components/MyNFTOnSaleCard";


export default {
  name: 'Home',
  data() {
    return{
      userAddress: '',
      MyNFTsOnSale:[],
      loading: false,
    }
  },
  components: {
    MyNFTOnSaleCard,
    Loading,
  },
  methods:{
    SwitchToDetailArt(Explore){
      this.$store.commit('GetDetailArtData',Explore)
      this.$router.push('/DetailArt')
    },
    async CancelFunction(nft){
      let marketAddress = ''
      if(nft.marketAddress) {
        marketAddress = nft.marketAddress
      } else {
        marketAddress = MARKET_ADDRESS_OLD
      }
      this.loading = true;
      let provider = this.$store.state.Provider;
      const signer = provider.getSigner()
      console.log("cancel");
      const contractMarketObject = new ethers.Contract(
          marketAddress,
          ABI_MARKET,
          signer
      );

      await contractMarketObject.createMarketCancel(nft.nftContract, nft.itemId).catch((error) => {
        console.log(error);
        this.loading = false;
        this.getOnSale();
        eventBus.$emit('saleCanceled');
      });

      let topic = ethers.utils.id("MarketItemSale(uint256,address,uint256,address,address,string,uint256,bool)");

      let filter = {
        address: marketAddress,
        topics: [ topic ]
      }

      provider.on(filter, () => {
        this.loading = false;
        this.getOnSale();
        eventBus.$emit('saleCanceled');
      });
    },
    async connectToMetaMask (){
      if (typeof window.ethereum !== 'undefined') {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        this.userAddress = accounts[0];
        this.$store.commit('SetUserAddress', this.userAddress);
        document.getElementById('connectButton').innerHTML = this.userAddress.slice(2,5)+"..." + this.userAddress.slice(-3);
      } else {
        console.log("No MetaMask available!");
      }
    },
    async getOnSale() {
      this.loading = true;
      this.userAddress = this.$store.state.UserAddress;
      if(this.userAddress === ""){
        await this.connectToMetaMask();
      }
      if(this.userAddress === ""){
        this.loading = false;
        return;
      }
      // console.log(this.userAddress)
      axios.get(API_URL+"nft/onMarket/"+this.userAddress).then(response => {
        this.MyNFTsOnSale = response.data;
        this.loading = false;
        // console.log(this.MyNFTsOnSale[0])
      });
    }
  },
  mounted() {
    this.getOnSale();
  },
  created() {
    eventBus.$on("placeOnMarket", () => {
      this.getOnSale();
    });
    eventBus.$on("saleCanceled", () => {
      this.getOnSale();
    });
  }

}
</script>




