<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
        <header class="modal-header" id="modalTitle">
          <h2 name="header">
            OpenTheta (V.2)
          </h2>
        </header>

        <section class="modal-body" id="modalDescription">
          <a target="_blank" href="https://opentheta.io">
            <h5>Go to current Marketplace</h5>
          </a>
          <p style="word-break: break-all;">
            This is a test version (v2) of our NFT marketplace. The smart contract our marketplace runs on is now audited by TechRate!
          </p>
          <h6>The backend smart contract changed</h6>
          <p>We changed the backend smart contract, please de-list and relist your NFTs.</p>
          <p>All newly listed items will be automatically listed on the new smart contract (0xbb5f35d40132a0478f6aa91e79962e9f752167ea).</p>
          <p>We added some security features and also added creator royalties</p>
          <p>Also we added a event for the TFuel transaction form the smart contract to the sellers wallet, so the theta team can try to integrate this in the explorer.</p>
          <h6>
            We are in no way affiliated with OpenSea or any other NFT marketplace.
          </h6>
          <a target="_blank" href="https://how-to.opentheta.io">
            <h5 id="how">How does it work? Click here</h5>
          </a>
          <h5>Supported NFTs</h5>
          <ul>
            <li>
              ThetaPunks
            </li>
            <li>
              ThetaZillas
            </li>
            <li>
              Theta Man on the Moon
            </li>
            <li>
              and more ...
            </li>
          </ul>
          <p>
            These are independent projects and do not have any relationship to this marketplace.
          </p>
          <h5>Fees are:</h5>
          <ul>
            <li>
              Sales Fee: 4%
            </li>
          </ul>
          <h5 id="websiteNotWorking" style="color: red">Website not working?</h5>
          <ol class="list-group-numbered">
            <li>
              Install MetaMask
            </li>
            <li>
              Set the chain to theta Mainnet
            </li>
          </ol>
          <p>
            Further, all assets that are displayed on the marketplace are loaded from our server.
            To improve the loading speed, we store assets that are currently on the marketplace on sale or have been sold.
            We do not store NFTs that are held in your wallet. All of the NFTs in your wallet are directly loaded from the blockchain API.
            This provides more security but also makes loading the MyAssets slow. We are currently working on improvements to the website's usability and
            speed. Please be patient!
          </p>
          <p>
            Please remember that we do not take any liability for losses or errors on the user
            side, the smart contract side, or the website. Usage only under your own risk!
          </p>
          <p>
            When using the marketplace please check that MetaMask is interacting with the correct smart contract:
          </p>
          <p>
          0xd539558887b6744c52c595cb24fb9efa664ba814 or 0x059377c014cfc12dd2612ebfe9cfd1a6fc1a8883 or 0xbb5f35d40132a0478f6aa91e79962e9f752167ea
          </p>
          <p>
            Projects that are launching and can be minted use their smart contracts.
            Ensure that you are allowed to use and trade on an NFT marketplace without KYC under your country's law.
            We do not take any responsibility!
          </p>
        </section>

        <footer class="modal-footer">
          <button type="button" class="btn-green" @click="close" aria-label="Close modal">
            I Understand
          </button>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "modalStart",
  methods: {
    close() {
      this.$emit('close');
    },
  },
}
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  top: 5%;
  bottom: 5%;
  left: 10%;
  right: 10%;
  width: 80%;
  height: 90%;
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  border-radius: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: #4AAE9B;
  justify-content: center;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
}

.modal-body {
  position: relative;
  padding: 20px 20px;
}

.modal-body p {
  padding-bottom: 10px;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 20px;
  cursor: pointer;
  font-weight: bold;
  color: #4AAE9B;
  background: transparent;
}

.btn-green {
  color: white;
  background: #4AAE9B;
  border: 1px solid #4AAE9B;
  border-radius: 5px;
  padding: 10px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .5s ease;
}
</style>