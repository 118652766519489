<template>
  <div class="ExploreArt MyNFTs">
    <div class="TitleAndFilter">
      <h2>My NFTs <p>{{ checkedProject }}</p></h2>
      <div class="Filter" @click="OpenCardFilter ? OpenCardFilter = false : OpenCardFilter= true">
        <button>
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
            <path d="M0 0h24v24H0V0z" fill="none"/>
            <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"/>
          </svg>
          Filter
        </button>
      </div>
      <div class="PopupFilter" v-if="OpenCardFilter" style="max-height: 380px;">
        <div class="creator">
          <h6>Projects</h6>
          <ul>
            <li v-for="(Project,n) in FilterProjects" :key="n">
              <input type="radio" :value="Project" v-model="checkedProject">
              <span>{{ Project }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="ExploreArt__Card">
      <!--                <MyNFTCard  v-for="(nft,n) in MyNFTs" :key="n" :nft="nft"></MyNFTCard>-->
      <div v-for="(nft,n) in MyNFTs" :key="n" :nft="nft">
        <MyNFTCard v-if="(nft.projectName === checkedProject || (checkedProject === 'ALL' && n < loadNumber))"
                   :nft="nft" :category="categories[nft.nftContract]"></MyNFTCard>
      </div>
    </div>

    <div v-if="loadNumber < totalNFTNumber && checkedProject === 'ALL'"
         style="justify-content: center; display: flex; margin-top: 50px">
      <button id="LoadButton" @click="loadMore('NEXT')">Load More</button>
    </div>
    <div v-if="loadNumber < totalNFTNumber && checkedProject === 'ALL'"
         style="justify-content: center; display: flex; margin-top: 50px">
      <a @click="loadMore('ALL')" style="color: lightgray">Load All</a>
    </div>

  </div>

</template>

<script>
import axios from "axios";
import API_URL, {ABI_NFT} from "../../Config";
// import API_URL from "../../Config";
import {ethers} from "ethers";
import MyNFTCard from "@/components/MyNFTCard"
import eventBus from "@/module/eventBus";
// import { request, gql } from "graphql-request"

export default {
  name: 'MyNFTs',
  data() {
    return {

      MyNFTs: [],
      MinPrice: '',
      MaxPrice: '',
      AllProjects: [],
      FilterProjects: ['ALL'],
      AllCreator: [],
      OpenCardFilter: false,
      userAddress: "",
      provider: null,
      SellApproveSend: "",
      checkedProject: "ALL",
      loadNumber: 6,
      totalNFTNumber: 0,
      categories: null,
    }
  },
  components: {
    MyNFTCard,
  },
  methods: {
    loadMore(loading) {
      if (loading === 'ALL') {
        this.loadNumber = this.totalNFTNumber
      } else if (loading === 'NEXT') {
        this.loadNumber += 6
      }
      console.log(this.totalNFTNumber)
    },
    GetAllProjects() {
      axios.get(`${API_URL}projects`).then((response) => {
        this.AllProjects = response.data
        // this.AllProjects.push({contract: "0xbb4d339a7517c81c32a01221ba51cbd5d3461a94", name:"TNS", description:"This is a Theta Name Service (TNS) domain"})
        // console.log("Projects Loaded")
        this.GetMyNFTs();
      });
    },
    // GetAllCreators(){
    //     axios.get(`${API_URL}projects`).then((response) => {
    //           let CreatorsHave=[]
    //           for(let i = 0 ; i <response.data.length ; i++){
    //             !(CreatorsHave.includes(response.data[i].creator)) ?
    //             CreatorsHave.push(response.data[i].creator)
    //             : ''
    //           }
    //           this.AllCreators = CreatorsHave
    //     });
    // },
    async connectToMetaMask() {
      if (typeof window.ethereum !== 'undefined') {
        const accounts = await window.ethereum.request({method: 'eth_requestAccounts'});
        this.userAddress = accounts[0];
        this.$store.commit('SetUserAddress', this.userAddress);
        document.getElementById('connectButton').innerHTML = this.userAddress.slice(2, 5) + "..." + this.userAddress.slice(-3);
      } else {
        console.log("No MetaMask available!");
        alert("No Metamask installed! Please install MetaMask.")
      }
    },
    async GetMyNFTs() {
      // console.log("get My NFTs")
      this.MyNFTs = []
      if (this.provider === null) {
        try {
          this.provider = new ethers.providers.Web3Provider(window.ethereum);
          this.$store.commit('SetProvider', this.provider);
        } catch (e) {
          console.log("No Metamask installed");
          alert("No Metamask installed! Please install MetaMask.")
        }
      }
      this.userAddress = this.$store.state.UserAddress;
      if (this.userAddress === "") {
        await this.connectToMetaMask();
      }
      // this.userAddress = "0x6b0090670f2Fc7B2365C06733B353Ee0F7041788";

      // axios.get(`${API_URL}user/${this.userAddress}`).then((response) => {
      //   this.MyNFTs = response.data
      // });

      let promisesNFTProjects = []

      for (let j = 0; j < this.AllProjects.length; j++) {
          promisesNFTProjects.push(this.getNFTs(this.AllProjects[j]))
      }
      await Promise.all(promisesNFTProjects)
    },
    async getNFTs(project) {
      if(project.contract === "0xbb4d339a7517c81c32a01221ba51cbd5d3461a94") {

        let tns = (await axios.get(`https://www.thetascan.io/api/721/?address=${this.userAddress.toLowerCase()}&contract=0xbb4d339a7517c81c32a01221ba51cbd5d3461a94`)).data

        if(!tns) return;
        this.FilterProjects.push(project.name);
        this.totalNFTNumber += tns.length
        for(let k=0; k<tns.length;k++) {
          let tokenId = tns[k].token
          // console.log(tokenId)
          // let labelHash = ethers.BigNumber.from(tokenId).toHexString()
          let url = 'https://opentheta.de/tns/' + tokenId
          // url = 'http://localhost:80/tns/' + tokenId
          let tnsName = (await axios.get(url)).data

          console.log(tnsName)
          if(tnsName) {
            let nft =  {
              name: tnsName + ".theta",
              imgUrl: "https://open-theta.de/api/images/creators/TNS.jpg",
              description: project.description,
              tokenId: tokenId,
              nftContract: project.contract,
              owner: this.userAddress,
              projectName: project.name,
            };
            // this.MyNFTs.push(nft);
            let setNFT = true;
            for (let i = 0; i < this.MyNFTs.length; i++) {
              if (this.MyNFTs[i].name === nft.name && this.MyNFTs[i].tokenId === nft.tokenId) {
                // console.log("NFT already exists")
                setNFT = false;
              }
            }
            if (setNFT) {
              this.MyNFTs.push(nft);
            }
          }

        }

        return
      }
      let projectAddress = project.contract
      const contractObject = new ethers.Contract(
          projectAddress,
          ABI_NFT,
          this.provider
      );
      // console.log(this.provider);
      let balance = await contractObject.balanceOf(this.userAddress).catch((error) => {
        console.log(error);
      });
      // console.log(balance);
      balance = balance.toNumber()
      if (balance > 0) {
        this.totalNFTNumber += balance
        this.FilterProjects.push(project.name);
        for (let i = 0; i < balance; i++) {
          // let myNFT;
          let tokenID = await contractObject.tokenOfOwnerByIndex(this.userAddress, i).catch((error) => {
            console.log(error);
          });
          // console.log(tokenID);
          let nftURI = await contractObject.tokenURI(tokenID).catch((error) => {
            console.log(error);
          });
          if (nftURI.slice(0, 4) === 'ipfs') {
            nftURI = 'https://ipfs.io/ipfs/' + nftURI.substring(7)
          }
          this.getURIData(nftURI, tokenID, project).then(nft => {
            let setNFT = true;
            for (let i = 0; i < this.MyNFTs.length; i++) {
              if (this.MyNFTs[i].name === nft.name && this.MyNFTs[i].tokenId.eq(nft.tokenId)) {
                // console.log("NFT already exists")
                setNFT = false;
              }
            }
            if (setNFT) {
              this.MyNFTs.push(nft);
            }
            // for (let i = 0; i<this.FilterProjects.length; i++) {
            //   if (this.FilterProjects[i].name === nft.projectName) {
            //     setNFT = false;
            //   }
            // }
            // if (setNFT){
            //   this.FilterProjects.push(nft.projectName);
            // }
          });
        }
      }
    },
    async getURIData(nftURI, tokenID, project) {
      let response = await axios.get(nftURI);
      if (response.data.image.slice(0, 4) === 'ipfs') {
        response.data.image = 'https://ipfs.io/ipfs/' + response.data.image.substring(7)
      }
      // console.log(response.data);
      return {
        name: response.data.name,
        imgUrl: response.data.image,
        description: response.data.description,
        tokenId: tokenID,
        nftContract: project.contract,
        owner: this.userAddress,
        projectName: project.name,
      };
    },
    loadData() {
      // console.log("Load Data")
      this.GetAllProjects();
      // this.GetAllCreators();
    }
  },
  mounted() {
    this.provider = this.$store.state.Provider;
    axios.get(`${API_URL}static/categories.json`).then(response => {
      this.categories = response.data
      this.loadData();
    });
  },
  created() {
    eventBus.$on("saleCanceled", () => {
      this.loadData();
    });
  }

}
</script>
