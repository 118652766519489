<template>
  <div class="footer">
      <div class="Footer_Content">
        <div class="LogoAndDescription">
            <ul>
                <li><router-link to="/"><img src="../assets/Img/OpenThetaLogo.png" alt=""></router-link> </li>
                <li><p>A marketplace where you can mint, buy and sell NFT tokens.</p></li>
            </ul>
        </div>
        <div class="AboutUs">
            <ul>
                <li><h6>Pages</h6></li>
                <li><router-link to="/"> Home</router-link></li>
                <li><router-link to="/Explore"> Explore</router-link></li>
                <li><router-link to="/MyNFTs"> My NFTs</router-link></li>
                <li><a target="_blank" href="https://opentheta.notion.site/Listed-Smart-Contract-Addresses-137f1860661042a6a1c9f648fa77a87b">Listed Smart Contracts</a></li>
            </ul>
        </div>
        <div class="Comunity">
            <ul>
              <li><h6>Community</h6></li>
              <li><a target="_blank" href="http://how-to.opentheta.io">Help Center</a></li>
              <li><a target="_blank" href="https://www.reddit.com/r/theta_network/">Reddit</a></li>
              <li><a target="_blank" href="https://github.com/OpenTheta/Smart-Contracts/blob/main/Audits/marketplaceV2.pdf">Audit</a></li>
              <li><a target="_blank" href="https://cvbhq5wfemw.typeform.com/to/UD2p1NHh">Become A Creator</a></li>
            </ul>
        </div>
        <div class="SocielMedia">
            <ul>
                <li><h6>Social Media</h6></li>
                <li><a target="_blank" href="https://twitter.com/OpenTheta">Twitter</a></li>
                <li><a target="_blank" href="https://discord.gg/Gzy9gvdzSS">Discord</a></li>
            </ul>
        </div>
      </div>
      <div class="Footer_Copyright">
          <p>@2021 OpenTheta. All rights reserved.</p>
      </div>

  </div>
</template>

<script>

export default {
  name: "features",
  data() {
    return {
    };
  },

  methods: {

  },
  mounted() {
  }
};
</script>
<style scoped>
.footer{
    background-image:url('../assets/Img/FooterBackground.png');
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
}
</style>


