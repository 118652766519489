import { render, staticRenderFns } from "./ExploreAuctions.vue?vue&type=template&id=fc2aafe4&scoped=true&"
import script from "./ExploreAuctions.vue?vue&type=script&lang=js&"
export * from "./ExploreAuctions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc2aafe4",
  null
  
)

export default component.exports