import { render, staticRenderFns } from "./AuctionCard.vue?vue&type=template&id=5ba5e1b3&scoped=true&"
import script from "./AuctionCard.vue?vue&type=script&lang=js&"
export * from "./AuctionCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ba5e1b3",
  null
  
)

export default component.exports