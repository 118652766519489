<template>
  <div id="app">
    <div class="Navbar">
      <div v-if="BurgerWork" class="NavbarMobileContent">
            <ul>
              <li><router-link to="/">Home</router-link>  </li>
              <li><router-link to="/Explore">Explore</router-link> </li>
              <!-- <li><router-link to="/DetailArt">Detail Art</router-link> </li> -->
              <li><router-link to="/MyNFTs">My NFTs</router-link> </li>
              <button id="connectButtonMobile" @click='ConnectWalletFunction()'>Connect Wallet</button>
            </ul>
      </div>
      <div class="container-fluid">
        <div class="DesktopNavbar">
          <div class="LeftNavbar">
            <ul>
              <li><router-link to="/"><img src="./assets/Img/Logo.png" alt=""></router-link> </li>
              <li><router-link to="/">Home</router-link>  </li>
              <li><router-link to="/Explore">Explore</router-link> </li>
              <!-- <li><router-link to="/DetailArt">Detail Art</router-link> </li> -->
              <li><router-link to="/MyNFTs">MyNFTs</router-link> </li>
            </ul>
          </div>
          <div class="RightNavbar">
              <button id="connectButton" @click='ConnectWalletFunction()'>Connect</button>
            <div v-if="userAddress !== ''" style="padding: 5px 0px; display: flex; justify-content: center; background: gray; border-radius: 5px">
              <span style="color: #FFFFFF;">{{ balance }}<img src="./assets/Img/tfuel_token.png" alt="" style="width: 25px; border-radius: 100%; padding-left: 5px;"/></span>
            </div>

          </div>
        </div>
        <div  class="MobileNavbar">
          <ul>
            <li><router-link to="/"><img src="./assets/Img/OpenThetaLogo1.png" alt=""></router-link> </li>
            <li>
              <div class="BurgerMenu" @click="OpenNavbarMobile">
                <span :class=" BurgerWork ? 'Burger_1' : '' "></span>
                <span :class=" BurgerWork ? 'Burger_2' : '' "></span>
                <span :class=" BurgerWork ? 'Burger_3' : '' "></span>
              </div>
            </li>
          </ul>
        </div>


      </div>

    </div>
    <router-view/>
    <Modal
        v-show="isModalVisible"
        @close="closeModal"
    />
<!--    <modalExplore-->
<!--        v-show="isModalExploreVisible"-->
<!--        @close="closeModalExplore"-->
<!--    />-->
  </div>
</template>

<script>
// import store from './store/index'
import {ethers} from 'ethers';
import Modal from './components/modalStart'
import axios from "axios";
import API_URL from "../Config";
// import modalExplore from "@/components/modalExplore";
// import eventBus from "@/module/eventBus";


export default {
  name: "features",
  data() {
    return {
      BurgerWork:false,
      userAddress: "",
      provider: null,
      isModalVisible: true,
      balance: 0,
      // isModalExploreVisible: false
    };
  },
  components: {
    Modal,
    // modalExplore
  },
  methods: {
    closeModal() {
      this.isModalVisible = false
    },
    OpenNavbarMobile(){
      this.BurgerWork ? this.BurgerWork =  false : this.BurgerWork = true;
      console.log(this.BurgerWork)
    },
    ConnectWalletFunction(){
      console.log("Connect wallet function");
      this.connectToMetaMask()
    },
    async connectToMetaMask (){
      if (typeof window.ethereum !== 'undefined') {
        await this.checkNetwork()
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        this.userAddress = accounts[0];
        // console.log(this.userAddress)
        this.$store.commit('SetUserAddress', this.userAddress);
        document.getElementById('connectButton').innerHTML = this.userAddress.slice(2,5)+"..." + this.userAddress.slice(-3);
        this.getBalance();
        if(this.BurgerWork) {
          document.getElementById('connectButtonMobile').innerHTML = this.userAddress.slice(2,5)+"..." + this.userAddress.slice(-3);
        }
      } else {
        console.log("No MetaMask available!");
      }
    },
    async getBalance() {
      this.balance = (ethers.BigNumber.from(await this.provider.getBalance(this.userAddress)).div(ethers.BigNumber.from("10000000000000000"))).toNumber()/100;
    },

    async checkNetwork() {
      let chainId = await window.ethereum.request({ method: 'eth_chainId' })
      if(chainId !== "0x169") {
        const validNetworkOptions = {
          chainId: "0x169",
          chainName: "Theta Mainnet",
          nativeCurrency: { name: "Theta Fuel", symbol: "TFUEL", decimals: 18 },
          rpcUrls: ["https://eth-rpc-api.thetatoken.org/rpc"],
          blockExplorerUrls: ["https://explorer.thetatoken.org/"],
        };
        window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [validNetworkOptions],
        });
      }
    },
    getCreators() {
      let AllCreators = {}
      if(!AllCreators.length){
        axios.get(`${API_URL}static/creators.json`).then((response) => {
          // AllCreators = response.data;
          // console.log(AllCreators)
          for(let i=0; i< response.data.length; i++){
            AllCreators[response.data[i].name] = API_URL + "images/creators/" + response.data[i].img
          }
          // console.log(AllCreators)
          this.$store.commit("SetCreatorsToImg", AllCreators);
        });
      }
    }
    // closeModalExplore() {
    //   this.isModalExploreVisible = false;
    // }
  },
  mounted() {
    this.getCreators()
    try {
      this.provider  = new ethers.providers.Web3Provider(window.ethereum);
      this.$store.commit('SetProvider', this.provider);
    } catch (e) {
      console.log("No Metamask installed");
    }
    // eventBus.$on("showNFTDetails", () => {
    //   this.isModalExploreVisible = true;
    // });
  }
};
</script>
<style lang="scss">
 @import 'assets/css/style.css';
</style>


