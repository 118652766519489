<template>
  <div class="ExploreArt">
    <div class="TitleAndFilter">
      <h2>Explore Projects</h2>
      <div class="Filter">
        <button @click="OpenCardFilter ? OpenCardFilter = false : OpenCardFilter= true">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#000000"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
          </svg>
          Filter
        </button>
      </div>
      <div class="PopupFilter" v-if="OpenCardFilter" style="width: 250px; max-height: 280px;">
        <div class="creator">
          <h6>Creators</h6>
          <ul>
            <li v-for="(Creator,n) in AllCreators" :key="n">
              <input type="radio" :value="Creator" v-model="checkedCreator" @change="setExploreProjects()">
              <span>{{Creator}}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="ExploreArt__Card">
      <div v-for="(Explore, n) in ExploreProjects" :key="n">
<!--        <projectCard v-if="(Explore.creator === checkedCreator || checkedCreator === 'ALL') && Explore.existsOnMarket === true" :project="Explore"></projectCard>-->
        <projectCard :project="Explore"></projectCard>
      </div>
    </div>
    <div v-if="loadNumber < totalProjectNumber"
         style="justify-content: center; display: flex; margin-top: 50px">
      <button id="LoadButton" @click="loadMore('NEXT')">Load More</button>
    </div>
    <div v-if="loadNumber < totalProjectNumber"
         style="justify-content: center; display: flex; margin-top: 50px">
      <a @click="loadMore('ALL')" style="color: lightgray">Load All</a>
    </div>
  </div>
</template>

<script>
import projectCard from "@/components/projectCard";
import axios from "axios";
import API_URL from "../../Config";

export default {
  name: "ExploreProjects",
  data() {
    return {
      checkedCreator: 'ALL',
      ExploreProjects: [],
      AllProjects: [],
      AllCreators: [],
      OpenCardFilter:false,
      loadNumber: 6,
      totalProjectNumber: 6
    };
  },
  components: {
    projectCard
  },
  methods: {
    loadMore(loading) {
      if (loading === 'ALL') {
        this.loadNumber = this.totalProjectNumber
      } else if (loading === 'NEXT') {
        this.loadNumber += 6
      }
      this.setExploreProjects()
    },
    setExploreProjects() {
      this.ExploreProjects = []
      this.totalProjectNumber = 6
      let projectCounter = 0
      let counter = 0;
       while(counter < this.AllProjects.length) {
         if(this.AllProjects[counter].existsOnMarket === true) {
           if(this.checkedCreator === 'ALL' || this.checkedCreator === this.AllProjects[counter].creator) {
              if(this.ExploreProjects.length < this.loadNumber) this.ExploreProjects.push(this.AllProjects[counter])
             projectCounter ++
           }
         }
        counter ++
      }
      this.totalProjectNumber = projectCounter
    },
    GetAllProjects(){
      axios.get(`${API_URL}projects`).then((response) => {
        this.AllProjects = response.data
        this.setExploreProjects();
        let CreatorsHave=[];
        for(let i = 0 ; i <response.data.length ; i++){
          !(CreatorsHave.includes(response.data[i].creator)) ?
              CreatorsHave.push(response.data[i].creator)
              : '';
        }
        this.AllCreators = ['ALL'].concat(CreatorsHave.sort());
      });
    },
  },
  mounted() {
    this.GetAllProjects();
  },
};
</script>



