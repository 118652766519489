<template>
  <div class="ExploreArt">
    <div class="TitleAndFilter" style="padding: 0px; margin-top: 50px">
      <h2>Mint New NFTs</h2>
    </div>
    <div class="ExploreArt__Card">
      <div v-for="(project, n) in launchProjects" :key="n">
        <LaunchCard :project="project"></LaunchCard>
      </div>
    </div>
  </div>

</template>

<script>

import LaunchCard from "@/components/LaunchCard";
import axios from "axios";
import API_URL from "../../Config";
// import projects from '../promotion/launchingProjects.json'


export default {
  name: "LaunchProjectsHome",
  data() {
    return {
      launchProjects:[],
      currentSupply: 0,
    };
  },
  components: {
    LaunchCard
  },
  methods: {
  },
  mounted() {
    // console.log(API_URL);
    axios.get(`${API_URL}static/launchingProjects.json`).then((response) => {
      this.launchProjects = response.data.slice(0,3);
    });
  },
};
</script>



