<template>
  <div class="Card" v-if="project.maxMint > currentSupply">
<!--    <iframe class="NFTImg" v-if="project.video === true" :src="project.imgUrl"-->
<!--            border="0"-->
<!--            width="100%"-->
<!--            height="100%"-->
<!--            autoplay loop/>-->
    <video class="NFTImg" style="background-color: black" v-if="project.video" :src="project.imgUrl" loop controls></video>
    <img v-else :src="project.imgUrl" alt="" class="NFTImg" @click="SwitchToLaunchpad(project)"/>
    <div class="AvatarName">
      <img
          :src="project.creatorUrl"
          alt=""
          style="width: 40px; height: 40px; border-radius: 100%"
      />
      <span>{{ project.creator }}</span>
    </div>
    <h3 v-if="project.name.length < 26">{{ project.name }}</h3>
    <h3 v-else>{{ project.name.slice(0,24) }}...</h3>
    <h5 v-if="project.endDate" style="padding-bottom: 5px; color: white" class="timer" v-bind:style="{ color: activeColor}">{{countdownTime}}</h5>
    <h5 v-if="project.endDate" style="padding-bottom: 5px; color: lightgray; font-size: 15px">Minted: {{currentSupply}}</h5>
    <h5 v-else style="padding-bottom: 5px; color: white">{{currentSupply}}/{{maxSupply}}</h5>
    <div class="BuyAndPrice" style="margin-bottom: 0px !important;">
      <button v-if="saleIsActive" @click="MintFunction()">Mint</button>
      <select v-if="project.batchMint && saleIsActive" v-model="selectedAmount" style="padding: 10px 5px; border-radius: 5px">
        <option v-for="i in project.batchSize" :key="i">{{i}}</option>
      </select>
      <h6 v-if="!saleIsActive && provider === null" style="padding: 15px; border-radius: 10px; background: gray">No MetaMask</h6>
      <h6 v-else-if="!saleIsActive && currentPrice === 0" style="padding: 15px; border-radius: 10px; background: gray">Wrong Network</h6>
      <h6 v-else-if="!saleIsActive" style="padding: 15px; border-radius: 10px; background: gray">Not Available</h6>
      <div class="Price">
        <h6>Price: <span v-if="priceDollar !== 0">$ {{ (priceDollar*selectedAmount).toLocaleString('en').replace(',', ' ')}}</span></h6>
        <h5>{{ (currentPrice * selectedAmount).toLocaleString('en').replace(',', ' ') }} <img src="./../assets/Img/tfuel_token.png" alt="" style="width: 25px; border-radius: 100%; padding-left: 3px"/></h5>
      </div>
    </div>
    <Loading :active.sync="loading"
             color="#18E5E7"
             background-color="#000"
             loader="dots"
    ></Loading>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {ethers} from "ethers";
import {ABI_NFT} from "../../Config";

export default {
  name: "LaunchCard",
  props: ['project'],
  data () {
    return {
      loading: false,
      currentPrice: null,
      currentSupply: 0,
      maxSupply: 100,
      saleIsActive: false,
      provider: null,
      countdownTimer:null,
      endDate: 'January 1, 2021 00:00:00',
      priceDollar: 0,
      countdownTime: null,
      activeColor: "#ffffff",
      selectedAmount: 1
    }
  },
  components: {
    Loading
  },
  methods: {
    SwitchToLaunchpad (project) {
      console.log("switch to launchpad", project);
    },
    async MintFunction () {
      this.loading = true
      console.log("mint", this.project.contract);
      // this.provider = new ethers.providers.JsonRpcBatchProvider();
      // console.log(overrides)
      if (typeof window.ethereum !== 'undefined') {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        try{
          const signer = this.provider.getSigner();
          const contractMintObject = new ethers.Contract(
              this.project.contract,
              ABI_NFT,
              signer
          );
          let price = 0;
          if(this.project.hasDiscount) {
            price = await contractMintObject.getDiscountedNFTPrice(accounts[0]);
            console.log("Price", price)
          } else {
            price = await contractMintObject.getNFTPrice();
          }

          // console.log(price)
          let overrides = {
            value: price.mul(this.selectedAmount),
          };
          // console.log(this.selectedAmount)
          // let promises = [
          //   contractMintObject.safeMint(accounts[0], overrides),
          //   contractMintObject.safeMint(accounts[0], overrides),
          // ]
          // await Promise.all(promises);
          console.log(overrides)
          if(parseInt(this.selectedAmount) === 1) await contractMintObject.safeMint(accounts[0], overrides);
          if(parseInt(this.selectedAmount) > 1) await contractMintObject.safeMintQuantity(accounts[0], this.selectedAmount, overrides);
          setTimeout(() => {
            this.getData();
            this.loading = false;
          },6000)
        } catch (e) {
          console.log(e);
          this.loading = false;
        }
      } else {
        console.log("No Metamask");
        this.loading = false;
      }
    },
    async getData() {
      // console.log(this.project)
      if(this.project.endDate){
        this.endDate = this.project.endDate
        this.countdown()
      }
      if(this.project.priceDollar){
        this.priceDollar = this.project.priceDollar;
      }
      this.provider  = new ethers.providers.Web3Provider(window.ethereum);
      let accounts;
      if (typeof window.ethereum !== 'undefined') {
        accounts = await window.ethereum.request({method: 'eth_requestAccounts'});
      }
      if(this.provider) {
        // this.loading = true;
        const contractMintObject = new ethers.Contract(
            this.project.contract,
            ABI_NFT,
            this.provider
        );
        // try{
        //   this.maxSupply = await contractMintObject.MAX_NFT_SUPPLY();
        // } catch{
          this.maxSupply = this.project.TotalAmount
        // }


        contractMintObject.totalSupply().then(res => {
          this.currentSupply = res.toNumber();
          if (this.currentSupply < this.maxSupply) {
            contractMintObject.saleIsActive().then(res => {
              this.saleIsActive = res;
              if(this.saleIsActive) {
                if(this.project.hasDiscount) {
                  contractMintObject.getDiscountedNFTPrice(accounts[0]).then(res => {
                    this.currentPrice = (ethers.BigNumber.from(res).div(ethers.BigNumber.from("1000000000000000000"))).toNumber();
                  }).catch((error) => {
                    console.log(error);
                    this.saleIsActive = false
                  });
                } else {
                  contractMintObject.getNFTPrice().then(res => {
                    this.currentPrice = (ethers.BigNumber.from(res).div(ethers.BigNumber.from("1000000000000000000"))).toNumber();
                  }).catch((error) => {
                    console.log(error);
                    // this.loading =false;
                  });
                }
                // contractMintObject.getNFTPrice().then(res => {
                //   this.currentPrice = (ethers.BigNumber.from(res).div(ethers.BigNumber.from("1000000000000000000"))).toNumber();
                // }).catch((error) => {
                //   console.log(error);
                //   // this.loading =false;
                // });
              }
            }).catch((error) => {
              console.log(error);
              // this.loading =false;
            });
          } else {
            this.saleIsActive = false;
            // this.loading =false;
          }
        }).catch((error) => {
          console.log(error);
          // this.loading =false;
        });
        // this.loading =false;
        // this.currentSupply = await contractObject.totalSupply();
      }
      // this.loading =false;
    },
    pad(n) {
      return (n < 10 ? "0" + n : n);
    },
    timer() {
      let endTime = new Date(this.endDate).getTime();
      let startTime = Date.now()
      let seconds = (endTime - startTime)/1000
      // console.log(seconds)
      if(seconds <= 0) {
        this.saleIsActive = false
        clearInterval(this.countdownTimer);
        document.getElementById('countdown').innerHTML = "Completed";
      } else {
        let days        = Math.floor(seconds/24/60/60);
        let hoursLeft   = Math.floor((seconds) - (days*86400));
        let hours       = Math.floor(hoursLeft/3600);
        let minutesLeft = Math.floor((hoursLeft) - (hours*3600));
        let minutes     = Math.floor(minutesLeft/60);
        let remainingSeconds = seconds % 60;
        try{
          if(days === 0){
            this.countdownTime = this.pad(hours) + "h " + this.pad(minutes) + "m " + this.pad(parseInt(remainingSeconds)) + "s";
            if(hours <= 12){
              this.activeColor = "#fd3232";
            }
          } else {
            this.countdownTime = this.pad(days) + "d " + this.pad(hours) + "h " + this.pad(minutes) + "m " + this.pad(parseInt(remainingSeconds)) + "s";
          }
        } catch{
          clearInterval(this.countdownTimer);
        }
      }
      // console.log(endTime, startTime)
    },
    countdown () {
      this.countdownTimer = setInterval(this.timer, 1000);
      this.timer()
    }
  },
  mounted () {
    this.getData();
  },
}
</script>

<style scoped>

</style>