<template>
  <div class="Card">
    <img :src="nft.imgUrl" alt="" class="NFTImg" @click="SwitchToDetailArt(nft)">
    <div class="AvatarName">
      <img :src="nft.ImgAvatar" alt="">
      <span>{{ nft.AvatarName }}</span>
    </div>
<!--      <h3 v-if="nft.name.length < 25">{{ nft.name }} <span v-if="rank!== ''" style="font-size: 12px; padding-left: 5px">Rank: {{ rank }}</span> <span style="font-size: 12px; padding-left: 5px" v-if="!/\d/.test(nft.name)">TokenID: {{ nft.tokenId }}</span></h3>-->
<!--      <h3 v-else>{{ nft.name.slice(0,25) }}...</h3>-->
    <h3 v-if="nft.name.length < 25">{{ nft.name }}</h3>
<!--    <h3 v-if="nft.name.length < 25">{{ nft.name }}<span style="padding-left: 5px" v-if="!/\d/.test(nft.name)  && nft.name.length < 21 && nft.tokenId.toString().length<6">#{{ nft.tokenId }}</span></h3>-->
    <h3 v-else-if="nft.name.length < 28" style="font-size: 18px">{{ nft.name}}</h3>
    <h3 v-else>{{ nft.name.slice(0,25) }}...</h3>
    <div>
      <span style="font-size: 12px; padding-left: 5px" v-if="rank!== ''">Rank: {{ rank }}</span>
      <span style="font-size: 12px; padding-left: 5px" v-if="nft.tokenId.toString().length<6">TokenID: {{ nft.tokenId }}</span>
    </div>
    <p v-if="!forSale" style="font-size: 10px">Listing paused!</p>
<!--    <div v-if="SellApproveSend === '' && nft.nftContract !== '0x3a8246be5efc8660a3618aefd9d767ae47df3c77'" class="BuyAndPrice">-->
      <div v-if="SellApproveSend === ''" class="BuyAndPrice">
      <button v-if="forSale" @click="SellApproveSend = 'approve'">Sell</button>
      <button @click="SellApproveSend = 'checkAddress'">Send</button>
    </div>
    <div v-if="SellApproveSend === 'approve'" class="BuyAndPrice">
      <button @click="ApproveFunction()">Approve</button>
    </div>
    <div v-if="SellApproveSend === 'sell'">
      <div class="BuyAndPrice InputField">
        <input v-model="price" placeholder="price ..." step=".01" type="number" @keypress="PriceFunction">
        <img alt="" src="./../assets/Img/tfuel_token.png" style="width: 25px; border-radius: 100%; padding-left: 3px"/>
      </div>
      <p style="width: 100%; text-align:center; color: #212121; font-size: 13px">3% OT + {{creatorFee}}% Creator Fee on sale</p>
      <div class="BuyAndPrice">
        <button v-if="nft.contract !== ''" @click="SellFunction()">Sell</button>
      </div>
    </div>

    <div v-if="SellApproveSend === 'checkAddress'">
      <div class="BuyAndPrice InputField">
        <input v-model="addressTo" placeholder="address" type="text">
      </div>
      <div class="BuyAndPrice">
        <button @click="CheckAddress()">Check</button>
      </div>
    </div>

    <div v-if="SellApproveSend === 'send'">
      <div class="BuyAndPrice">
        <!--        <span>{{addressTo.slice(0,7)+"..." + addressTo.slice(-7)}}</span>-->
        <p style="font-size: 12px;">{{ addressTo }}</p>
      </div>
      <div class="BuyAndPrice">
        <button @click="SendFunction()">Send</button>
      </div>
    </div>

    <div v-if="SellApproveSend !== ''" class="BuyAndPrice" style="margin: 30px 0px 0px 0px;"
         @click="SellApproveSend = ''">
      <a>Back</a>
    </div>
    <Loading :active.sync="loading"
             background-color="#000"
             color="#18E5E7"
             loader="dots"
    ></Loading>
  </div>
</template>

<script>
// import axios from "axios";
// import API_URL from "../../Config";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {ABI_MARKET, ABI_NFT, MARKET_ADDRESS} from "../../Config";
import {ethers} from "ethers";
import eventBus from "@/module/eventBus";
import ThetaPunksRarity from '../promotion/rarityThetaPunks.json'


export default {
  name: 'MyNFTCard',
  props: ['nft', 'category'],
  data() {
    return {
      addressTo: '',
      userAddress: "",
      provider: null,
      SellApproveSend: "",
      price: null,
      loading: false,
      rank: '',
      forSale: true,
      creatorFee: 0,
    }
  },
  components: {
    Loading
  },
  methods: {
    SwitchToDetailArt(nft) {
      this.$store.commit('GetDetailArtData', nft);
      this.$router.push('/DetailArt');
    },
    async ApproveFunction() {
      this.loading = true;
      console.log("approve")
      const signer = this.provider.getSigner();

      const contractMarketObject = new ethers.Contract(
          MARKET_ADDRESS,
          ABI_MARKET,
          signer
      );

      console.log(this.nft.nftContract)
      contractMarketObject.getCreatorFeeBasisPoints(this.nft.nftContract).then(res => {
        this.creatorFee = res[1].toNumber()/100
      })

      const contractNFTObject = new ethers.Contract(
          this.nft.nftContract,
          ABI_NFT,
          signer
      );

      let address = await contractNFTObject.getApproved(this.nft.tokenId).catch((error) => {
        console.log(error);
      });

      // get Items on sale from smart contract
      if (address !== '' && MARKET_ADDRESS === address.toLowerCase()) {
        this.SellApproveSend = 'sell';
        this.loading = false;
        return;
      }

      await contractNFTObject.approve(MARKET_ADDRESS, this.nft.tokenId).catch((error) => {
        console.log(error);
        this.loading = false;
      });

      let topic = ethers.utils.id("Approval(address,address,uint256)");

      let filter = {
        address: this.nft.NFTContract,
        topics: [topic]
      }

      this.provider.on(filter, () => {
        this.SellApproveSend = 'sell';
        this.loading = false;
      });
    },
    async SellFunction() {
      if(this.forSale === false) return;
      this.loading = true;
      console.log("sell", this.price);
      if (this.price <= 0) {
        alert("Unvalid Price set");
        this.loading = false;
        return;
      }
      const signer = this.provider.getSigner()
      const contractMarketObject = new ethers.Contract(
          MARKET_ADDRESS,
          ABI_MARKET,
          signer
      );

      let priceWei = ethers.BigNumber.from(this.price * 100).mul(ethers.BigNumber.from("10000000000000000"));

      let cat
      if(this.category){
        cat  = this.category
      } else {
        cat  = this.nft.projectName
      }

      await contractMarketObject.createMarketItem(this.nft.nftContract, this.nft.tokenId, priceWei, cat).catch((error) => {
        console.log(error);
        this.SellApproveSend = '';
        this.loading = false;
        this.$parent.GetMyNFTs();
        eventBus.$emit('placeOnMarket');
      });

      let topic = ethers.utils.id("MarketItemCreated(uint256,address,uint256,address,address,string,uint256,bool)");

      let filter = {
        address: MARKET_ADDRESS,
        topics: [topic]
      }

      this.provider.on(filter, () => {
        this.SellApproveSend = '';
        this.loading = false;
        this.$parent.GetMyNFTs();
        eventBus.$emit('placeOnMarket');
      });
    },
    async SendFunction() {
      this.loading = true;
      console.log("send");
      let userAddress = this.$store.state.UserAddress;
      const signer = this.provider.getSigner()
      const contractNFTObject = new ethers.Contract(
          this.nft.nftContract,
          ABI_NFT,
          signer
      );
      try {
        await contractNFTObject.transferFrom(userAddress, this.addressTo, this.nft.tokenId);
      } catch {
        this.loading = false;
        console.log("Transfer was denied");
      }

      let topic = ethers.utils.id("Transfer(address,address,uint256)");

      let filter = {
        address: this.nft.nftContract,
        topics: [
          topic,
          ethers.utils.hexZeroPad(userAddress, 32),
          ethers.utils.hexZeroPad(this.addressTo, 32),
        ]
      }

      this.provider.on(filter, () => {
        this.SellApproveSend = '';
        this.addressTo = '';
        this.loading = false;
        this.$parent.GetMyNFTs();
      });
    },
    CheckAddress() {
      try {
        ethers.utils.getAddress(this.addressTo);
        this.SellApproveSend = 'send';
      } catch {
        console.log("Address invalid");
        this.addressTo = ''
      }
    },
    PriceFunction($event) {
      // console.log($event.keyCode); //keyCodes value
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);

      // only allow number and one dot
      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || this.price.indexOf('.') != -1)) { // 46 is dot
        $event.preventDefault();
      }

      // restrict to 2 decimal places
      if (this.price != null && this.price.indexOf(".") > -1 && (this.price.split('.')[1].length > 1)) {
        $event.preventDefault();
      }
    }
  },
  mounted() {
    if(this.category){
      console.log(this.category)
    } else {
      console.log(this.nft.nftContract)
    }
    this.provider = this.$store.state.Provider;
    if(this.nft.nftContract.toLowerCase() === '0xbb4d339a7517c81c32a01221ba51cbd5d3461a94'){
      this.forSale = false
    }
    if (ThetaPunksRarity[this.nft.name]) {
      this.rank = ThetaPunksRarity[this.nft.name];
    }
  },

}
</script>
<style>
.InputField input {
  width: 60%;
  padding: 10px 10px;
  border-radius: 5px;
}

</style>
