<template>
  <div class="ExploreArt" v-if="auctions.length > 0">
    <div class="TitleAndFilter">
      <h2>Auctions</h2>
    </div>
    <div class="ExploreArt__Card">
      <div v-for="(auction, n) in auctions" :key="n">
        <AuctionCard :auction="auction"></AuctionCard>
      </div>
    </div>
  </div>
</template>

<script>
import AuctionCard from "@/components/AuctionCard";
import axios from "axios";
import API_URL from "../../Config";

export default {
  name: "ExploreAuctions",
  data() {
    return {
      auctions:[],
    };
  },
  components: {
    AuctionCard
  },
  mounted() {
    axios.get(`${API_URL}static/auctions.json`).then((response) => {
      this.auctions = response.data;
    });
  },
}
</script>

<style scoped>

</style>