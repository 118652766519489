<template>
  <div class="Card">
    <img :src="project.imgUrl" alt="" class="NFTImg" @click="SwitchToExplore(project)"/>
    <div class="AvatarName">
      <span>{{ project.creator }}</span>
    </div>
    <h3>{{ project.name }}</h3>
  </div>
</template>

<script>
export default {
  name: "projectCard",
  props: ['project'],
  methods: {
    SwitchToExplore(project) {
      this.$store.commit('SetExploreData', project.contract);
      this.$router.push("/Explore");
      window.scrollTo(0, 800);
    },
  }
}
</script>

<style scoped>

</style>