import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    DeaylArtData:null,
    ExploreProject: null,
    UserAddress: "",
    Provider: null,
    Search: null,
    Creators: [],
    CreatorsToImg: [],
  },
  mutations: {
    GetDetailArtData(state,NFT){
      state.DeaylArtData = NFT;
    },
    SetExploreData(state, contract){
      state.ExploreProject = contract;
    },
    SetUserAddress(state, address){
      state.UserAddress = address;
    },
    SetProvider(state, provider){
      state.Provider = provider;
    },
    SetSearch(state, search){
      state.Search = search;
    },
    SetCreatorsToImg(state, creators){
      state.CreatorsToImg = creators;
    }
  },
  actions: {
  },
  modules: {
  }
})
