<template>
  <div class="home Explore">
    <div class="Hero">
        <div class="container-fluid">
            <div class="Hero__Content">
                <h1>Explore Now</h1>
                <h1> & Find The Next NFT</h1>
                <div class="SearchInput">
                  <autocomplete
                      :search="search"
                      placeholder="Search NFTs, Projects and Creators"
                      aria-label="Search NFTs"
                      @submit="submit"
                      style="color: #212121; width: 100%"
                  ></autocomplete>
<!--                  <input type="text" class="inpsearch" list="mysearch" placeholder="Search On Marketplace ..." @keyup="SearchFunction()" v-model="BarSearch">-->
                      <button @click="Search()">Search</button>
                </div>
            </div>
        </div>
    </div>
    <div class="ExploreArt" v-if="SearchClicked">
      <div class="ExploreArt__Card">
        <div v-for="(project, n) in searchedLaunchProjects" :key="n">
          <LaunchCard :project="project"></LaunchCard>
        </div>
      </div>
    </div>
    <div class="PopupFilter PopupFilterExplore" v-if="SearchClicked">
                      <div class="Project">
                          <h6>Projects</h6>
                            <ul>
                              <li v-for="(Project,n) in AllProjects" :key="n">
                                <input type="checkbox"
                                       :checked="checkedProjects.includes(Project.name)"
                                       @change="checkProject($event, Project)">
                                <span>{{Project.name}}</span>
                              </li>
                          </ul>
                      </div>
                      <div class="creator">
                          <h6>Creators</h6>
                          <ul>
                              <li v-for="(Creator,n) in AllCreators" :key="n">
                                <input type="checkbox"
                                       :checked="checkedCreators.includes(Creator)"
                                       @change="checkCreator($event, Creator)">
                                <span>{{Creator}}</span>
                              </li>
                          </ul>
                      </div>
                      <div class="Price">
                          <h6>Price</h6>
                          <button v-on:click="SortPrice('ascending')">Ascending</button>
                          <button v-on:click="SortPrice('descending')">Descending</button>
                          <input type="number" placeholder="Min" v-model="MinPrice">
                          <input type="number" placeholder="Max" v-model="MaxPrice">
                        <button v-on:click="Search()">Filter Price</button>
                      </div>
    </div>
    <div class="ExploreArt" v-if="SearchClicked">
      <div class="ExploreArt__Card">
                    <div  v-for="NFT in ResultSearch" :key="NFT.itemId">
                        <buyNFTCard :nft="NFT"></buyNFTCard>
                    </div>
      </div>
      <div style="justify-content: center; display: flex; margin-top: 50px">
        <button id="LoadButton" @click="loadMore(6)" v-if="BatchSize === ResultSearch.length">Load More</button>
      </div>
      <div style="justify-content: center; display: flex; margin-top: 50px">
        <a @click="loadMore(10000)" style="color: lightgray" v-if="BatchSize === ResultSearch.length">Load All</a>
      </div>
    </div>

    <LaunchProjects v-if="!SearchClicked"></LaunchProjects>
<!--    <ExploreCreators v-if="!SearchClicked"></ExploreCreators>-->
    <RecentlyListed v-if="!SearchClicked"></RecentlyListed>
    <RecentlySold v-if="!SearchClicked" :contract="'ALL'"/>
<!--    <NewArtistCmpt v-if="!SearchClicked"/>-->
<!--    <NewCryptoCmpt  v-if="!SearchClicked"/>-->
    <Myfooter/>
  </div>
</template>

<script>
import Myfooter from '../components/Footer.vue'
import buyNFTCard from "@/components/buyNFTCard";
// import ExploreCreators from "@/components/ExploreCreators";
// import NewArtistCmpt from '../components/NewArtist.vue'
// import NewCryptoCmpt from '../components/NewCryptoArt.vue'
import RecentlyListed from "@/components/RecentlyListed";
import LaunchProjects from "@/components/LaunchProjects";
import LaunchCard from "@/components/LaunchCard";

import axios from "axios";
import API_URL from "../../Config";
import {ethers} from "ethers";
import RecentlySold from "@/components/RecentlySold";

// import projects from '../promotion/launchingProjects.json'
import Autocomplete from '@trevoreyre/autocomplete-vue'

export default {
  name: 'Home',
  data() {      
    return{
      SearchNft : '',
      BarSearch:'',
      MinPrice:'',
      MaxPrice:'',
      AllProjects:[],
      AllCreators:[],
      OpenCardFilter :false,
      SearchClicked:false,
      ResultSearch:[],
      checkedProjects: [],
      checkedCreators: [],
      mintable: false,
      searchedLaunchProjects:[],
      AllToSearch: [],
      BatchSize:6,
      Sorting: 'none',
      position: 0,
      launchingProjects: null,
      queryProjects: [],
      queryCreators: []
    }
  },
  components: {
    RecentlySold,
    Myfooter,
     // NewArtistCmpt,
     // NewCryptoCmpt,
    // ExploreCreators,
    LaunchProjects,
    RecentlyListed,
    buyNFTCard,
    LaunchCard,
    Autocomplete
  },
  methods:{
    // SearchFunction(){
    //   console.log(this.BarSearch)
    //   // if(this.BarSearch == ""){
    //   //   this.SearchClicked = false
    //   // }
    // },
    search(input) {
      this.BarSearch = input
      if (input.length < 1) { return [] }

      return this.AllToSearch.filter(res => {
        return res.toLowerCase()
            .startsWith(input.toLowerCase())
      })
    },
    submit(result) {
      this.BarSearch = result
    },
    loadMore(number) {
      this.BatchSize += number;
      this.Search();
    },
    Search(){
      // this.BarSearch =document.getElementsByClassName('autocomplete-input')[0].value
      this.SearchClicked = true;
      let search = "";
      if(this.BarSearch === ""){
        search ="ALL";
      } else {
        search = this.BarSearch;
      }
      let min = '0';
      let max = '0';
      if(this.MinPrice !== '') min = this.MinPrice;
      if(this.MaxPrice !== '') max = this.MaxPrice;
      if(this.checkedProjects.length && this.checkedCreators.length) {
        axios
            .get(`${API_URL}search/${search}/${this.BatchSize}/${this.Sorting}/${min}/${max}?projects=["${this.checkedProjects.join('","')}"]&creators=["${this.checkedCreators.join('","')}"]`)
            .then(response => {
              this.ResultSearch = response.data
              if(this.position !== 0){
                setTimeout(() => {
                  window.scrollTo(0, this.position);
                  this.position = 0;
                },500)
              }
              // this.FilterPrice();
            })
      } else if(this.checkedProjects.length) {
        axios
            .get(`${API_URL}search/${search}/${this.BatchSize}/${this.Sorting}/${min}/${max}?projects=["${this.checkedProjects.join('","')}"]`)
            .then(response => {
              this.ResultSearch = response.data
              if(this.position !== 0){
                setTimeout(() => {
                  window.scrollTo(0, this.position);
                  this.position = 0;
                },500)
              }
              // this.FilterPrice();
            })
      } else if(this.checkedCreators.length) {
        axios
            .get(`${API_URL}search/${search}/${this.BatchSize}/${this.Sorting}/${min}/${max}?creators=["${this.checkedCreators.join('","')}"]`)
            .then(response => {
              this.ResultSearch = response.data
              if(this.position !== 0){
                setTimeout(() => {
                  window.scrollTo(0, this.position);
                  this.position = 0;
                },500)
              }
              // this.FilterPrice();
            })
      } else {
        axios
            .get(`${API_URL}search/${search}/${this.BatchSize}/${this.Sorting}/${min}/${max}`)
            .then(response => {
              this.ResultSearch = response.data
              if(this.position !== 0){
                setTimeout(() => {
                  window.scrollTo(0, this.position);
                  this.position = 0;
                },500)
              }
              // this.FilterPrice();
            })
      }
      let searchItems = {
        search: search,
        BatchSize: this.BatchSize,
        sorting: this.Sorting,
        minimum: min,
        maximum: max,
        projects: this.checkedProjects,
        creators: this.checkedCreators,
        position: this.position,
        use: false,
      }
      this.$store.commit('SetSearch', searchItems);
      this.checkMintable()
    },
    checkProject(event, project){
      if (event.target.checked) {
        this.checkedProjects.push(project.name);
        // console.log(project.name);
      } else {
        let index = this.checkedProjects.indexOf(project.name);
        if (index !== -1) {
          this.checkedProjects.splice(index, 1);
        }
      }
      // console.log(this.checkedProjects);
      this.BatchSize = 6;
      this.Search();
      // this.checkMintable()
    },
    checkCreator(event, creator){
      if (event.target.checked) {
        this.checkedCreators.push(creator);
        // console.log(creator);
      } else {
        let index = this.checkedCreators.indexOf(creator);
        if (index !== -1) {
          this.checkedCreators.splice(index, 1);
        }
      }
      // console.log(this.checkedCreators);
      this.BatchSize = 6;
      this.Search();
      // this.checkMintable()
    },
    async checkMintable() {
      if(this.launchingProjects === null){
        this.searchedLaunchProjects = [];
        this.launchingProjects = (await axios.get(`${API_URL}static/launchingProjects.json`)).data
      } else {
        this.searchedLaunchProjects = [];

        await setTimeout(() => {
        },500)
      }
      // this.searchedLaunchProjects = [];
      for(let i=0; i< this.checkedCreators.length; i++){
        for(let j=0; j< this.launchingProjects.length; j++){
          if(this.checkedCreators[i] === this.launchingProjects[j].creator){
            this.searchedLaunchProjects.push(this.launchingProjects[j])
          }
        }
      }
      for(let i=0; i< this.checkedProjects.length; i++){
        // console.log(this.checkedProjects[i].replace(/\s/g, '').substring(0,14))
        for(let j=0; j< this.launchingProjects.length; j++){
          if(this.checkedProjects[i].replace(/\s/g, '').substring(0,14) === this.launchingProjects[j].name.replace(/\s/g, '').substring(0,14)){
            !(this.searchedLaunchProjects.includes(this.launchingProjects[j])) ?
                this.searchedLaunchProjects.push(this.launchingProjects[j])
                : ''
          }
        }
      }
      // console.log(this.searchedLaunchProjects)
    },
    // async FilterPrice(){
      // let min = parseInt(this.MinPrice);
      // let max = parseInt(this.MaxPrice);
      // if(max && min){
      //   this.ResultSearch = this.ResultSearch.filter(function (nft) {
      //     return (ethers.BigNumber.from(nft.price).div(ethers.BigNumber.from("1000000000000000000"))).toNumber() <= max &&
      //         (ethers.BigNumber.from(nft.price).div(ethers.BigNumber.from("1000000000000000000"))).toNumber() >= min;
      //   });
      // } else if(max) {
      //   this.ResultSearch = this.ResultSearch.filter(function (nft) {
      //     return (ethers.BigNumber.from(nft.price).div(ethers.BigNumber.from("1000000000000000000"))).toNumber() <= max;
      //   });
      // } else if(min) {
      //   this.ResultSearch = this.ResultSearch.filter(function (nft) {
      //     return (ethers.BigNumber.from(nft.price).div(ethers.BigNumber.from("1000000000000000000"))).toNumber() >= min;
      //   });
      // }
    // },
    GetAllProjects(){
        axios.get(`${API_URL}projects`).then((response) => {
          this.AllProjects = response.data.sort((a, b) => a.name.localeCompare(b.name))
          // console.log(this.AllProjects)
          for(let i=0; i<this.AllProjects.length; i++){
            !(this.AllToSearch.includes(this.AllProjects[i].creator)) ?
                this.AllToSearch.push(this.AllProjects[i].creator)
                : '';
            !(this.AllToSearch.includes(this.AllProjects[i].name)) ?
                this.AllToSearch.push(this.AllProjects[i].name)
                : '';
          }

          let allProjectNames = this.AllProjects.map(function (obj) {
            return obj.name;
          });

          for(let i=0; i<this.queryProjects.length; i++) {
            (!(this.checkedProjects.includes(this.queryProjects[i])) && allProjectNames.includes(this.queryProjects[i])) ?
                this.checkedProjects.push(this.queryProjects[i])
                : ''
          }
          if(this.checkedProjects.length && this.queryProjects.length) {
            this.SearchClicked = true;
            this.Search()
          }
          this.queryProjects = []
        });
    },
    GetAllCreators(){
        axios.get(`${API_URL}projects`).then((response) => {
          let CreatorsHave=[]
          for(let i = 0 ; i <response.data.length ; i++){
            !(CreatorsHave.includes(response.data[i].creator)) ?
            CreatorsHave.push(response.data[i].creator)
            : ''
          }
          this.AllCreators = CreatorsHave.sort()
          for(let i=0; i<this.queryCreators.length; i++) {
            (!(this.checkedCreators.includes(this.queryCreators[i])) && this.AllCreators.includes(this.queryCreators[i])) ?
                this.checkedCreators.push(this.queryCreators[i])
                : ''
          }
          if(this.checkedCreators.length && this.queryCreators.length) {
            this.SearchClicked = true;
            this.Search()
          }
          this.queryCreators = []
        });
    },
    SwitchToDetailArt(Explore){
      this.$store.commit('GetDetailArtData',Explore)
      this.$router.push('/DetailArt')
    },
    SortPrice(sorting) {
      this.Sorting = sorting;
      this.Search();
      // if (ascending) {
    //     this.ResultSearch.sort((a, b) => {
    //       return (a.price - b.price); // ascending
    //     });
    //   } else {
    //     this.ResultSearch.sort((a, b) => {
    //       return (b.price - a.price); // descending
    //     });
    //   }
    },
  },
  mounted() {
    if(Array.isArray(this.$route.query.project)) {
      this.queryProjects = this.$route.query.project
    } else if(this.$route.query.project !== undefined) {
      this.queryProjects.push(this.$route.query.project)
    }
    if(Array.isArray(this.$route.query.creator)) {
      this.queryCreators = this.$route.query.project
    } else if(this.$route.query.creator !== undefined) {
      this.queryCreators.push(this.$route.query.creator)
    }
    this.GetAllProjects();
    this.GetAllCreators();
    this.BatchSize = 6;
    if (this.$store.state.ExploreProject) {
      window.scrollTo(0, 780);
      this.SearchClicked = true
      try {
        ethers.utils.getAddress(this.$store.state.ExploreProject);
        axios
            .get(`${API_URL}projects/${this.$store.state.ExploreProject}/nft/on-market`)
            .then(response => {
              this.ResultSearch = response.data.slice(0,this.BatchSize);
              if(this.ResultSearch[0]) {
                this.checkedProjects.push(this.ResultSearch[0].projectName);
                this.checkMintable()
              }
              let searchItems = {
                search: 'ALL',
                BatchSize: this.BatchSize,
                sorting: this.Sorting,
                minimum: 0,
                maximum: 0,
                projects: this.checkedProjects,
                creators: this.checkedCreators,
                position: 780,
                use: false,
              }
              this.$store.commit('SetSearch', searchItems);
            })
        this.$store.commit('SetExploreData', null);
      } catch {
        this.checkedCreators.push(this.$store.state.ExploreProject);
        this.$store.commit('SetExploreData', null);
        // this.checkMintable()
        this.Search()
      }
    } else {
      if(this.$store.state.Search && this.$store.state.Search.use){
        this.SearchClicked = true
        this.BarSearch = this.$store.state.Search.search;
        this.BatchSize = this.$store.state.Search.BatchSize;
        this.Sorting = this.$store.state.Search.sorting;
        this.MaxPrice = this.$store.state.Search.maximum;
        this.MinPrice = this.$store.state.Search.minimum;
        this.checkedProjects = this.$store.state.Search.projects;
        this.checkedCreators = this.$store.state.Search.creators;
        if(this.$store.state.Search.position){
          this.position = this.$store.state.Search.position
        }
        this.Search()

      }
    }

    // else {
    //   window.scrollTo(0, 0);
    // }
  },

}
</script>
<style>
#LoadButton{
  width: 200px;
  padding: 10px;
  border-radius: 20px;
  border-width: 1px;
  border-color: white;
  border-style: solid;
  background: transparent;
  color: #FFFFFF;
}

.autocomplete-input{
  border:1px solid #eee;
  border-radius:8px;
  width:100% !important;
  padding:10px 10px 10px 40px;
  box-sizing:border-box;
  position:relative;
  font-size:16px;
  line-height:1.5;
  flex:1;
  /*background-color:#eee;background-image:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNjY2IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PGNpcmNsZSBjeD0iMTEiIGN5PSIxMSIgcj0iOCIvPjxwYXRoIGQ9Ik0yMSAyMWwtNC00Ii8+PC9zdmc+");*/
  background-repeat:no-repeat;
  background-position:12px;
}
.autocomplete-input:focus,.autocomplete-input[aria-expanded=true]{
  border-color:rgba(0,0,0,.12);
  background-color:#fff;
  outline:none;
  box-shadow:0 2px 2px rgba(0,0,0,.16)
}
[data-position=below] .autocomplete-input[aria-expanded=true]{
  border-bottom-color:transparent;
  border-radius:8px 8px 0 0
}
[data-position=above] .autocomplete-input[aria-expanded=true]{
   border-top-color:transparent;
   border-radius:8px 8px 8px 8px;
   z-index:2
 }
.autocomplete[data-loading=true]:after{
  content:"";
  border:3px solid rgba(0,0,0,.12);
  border-right-color:rgba(0,0,0,.48);
  border-radius:100%;
  width:20px;
  height:20px;
  position:absolute;
  right:12px;top:50%;
  transform:translateY(-50%);
  animation:rotate 1s linear infinite
}
.autocomplete-result-list{
  margin:0;
  border:1px solid rgba(0,0,0,.12);
  padding:0;
  box-sizing:border-box;
  max-height:150px;
  overflow-y:auto;
  background:#fff;
  list-style:none;
  box-shadow:0px 2px 2px rgba(0,0,0,.16)
}
[data-position=below] .autocomplete-result-list{
  margin-top:1px;
  border-top-color:transparent;
  border-radius:8px 8px 8px 8px;
  padding-bottom:8px;
  width: 100px
}
[data-position=above] .autocomplete-result-list{
  margin-bottom:-1px;
  border-bottom-color:transparent;
  border-radius:8px 8px 0 0;
  padding-top:8px
}
.autocomplete-result{
  cursor:default;
  padding:12px 12px 12px 48px;
  background-image:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjY2NjIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PGNpcmNsZSBjeD0iMTEiIGN5PSIxMSIgcj0iOCIvPjxwYXRoIGQ9Ik0yMSAyMWwtNC00Ii8+PC9zdmc+");
  background-repeat:no-repeat;
  background-position:12px
}
.autocomplete-result:hover,.autocomplete-result[aria-selected=true]{
  background-color:rgba(0,0,0,.06)
}
@keyframes rotate{
  0%{
    transform:translateY(-50%) rotate(0deg)
  }
  to{
    transform:translateY(-50%) rotate(359deg)
  }
}
.Hero{
    background-image:url('../assets/Img/HeroBackground.png');
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
}
.StayInTheTop__content{
      background-image:url('../assets/Img/SectionBackground.png');
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
}
</style>

