<template>
    <div class="RecentlySold">
      <div class="container-fluid">
            <div class="TitreAndButtonSwitch">
                <h2>Recently sold</h2>
                <div class="BtnSwitch">
                    <button @click="GetBack()"><svg   xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"/></svg></button>
                    <button  @click="GetNext()"><svg   xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M10.02 6L8.61 7.41 13.19 12l-4.58 4.59L10.02 18l6-6-6-6z"/></svg></button>
                </div>
            </div>
            <div class="ZoneSpiner" v-if="SpinnerWork">
              <div class="spinner-border" role="status">
                <span class="sr-only"></span>
              </div>
            </div>

            <div class="RacebltySold__Content">
                <div v-for="nft in RecentlySold.slice(StartNumberRecentlySoldShow,EndNumberRecentlySoldShow)" :key="nft.itemId">
                  <recentlySoldBar :nft="nft"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import API_URL from "../../Config";
import recentlySoldBar from "@/components/recentlySoldBar";

export default {
  name: 'RecentlySold',
  props: ['contract'],
  data() {      
    return{
        RecentlySold:[
         
        ],
        StartNumberRecentlySoldShow:0,
        EndNumberRecentlySoldShow:10,
        GetFirstBatch:10,
        SpinnerWork:false,
    }
  },
  components: {
    recentlySoldBar
  },
  methods:{
    ShowNewRecentlySold(Condition){
      if(Condition === "Next"){
        if(!(this.EndNumberRecentlySoldShow >= this.RecentlySold.length)){
            this.StartNumberRecentlySoldShow +=10
            this.EndNumberRecentlySoldShow +=10
        }
      }
      else{
        if(this.StartNumberRecentlySoldShow !== 0){
            this.StartNumberRecentlySoldShow -=10
            this.EndNumberRecentlySoldShow -=10
        }
      }
    },
    GetNext(){
        this.GetFirstBatch +=10
        this.StartNumberRecentlySoldShow +=10
        this.EndNumberRecentlySoldShow +=10
        this.GetRecentlySoldData(this.GetFirstBatch)
    },
    GetBack(){
      if(this.StartNumberRecentlySoldShow !== 0){
          this.GetFirstBatch -=10
          this.StartNumberRecentlySoldShow -=10
          this.EndNumberRecentlySoldShow -=10
          this.GetRecentlySoldData(this.GetFirstBatch)
      }

      console.log(this.StartNumberRecentlySoldShow , this.EndNumberRecentlySoldShow )
    },
    async GetRecentlySoldData(Number){
      this.SpinnerWork = true
      if(this.contract === null){
        await setTimeout(() => {
        },50)
      }
      if(this.contract === 'ALL'){
        this.SpinnerWork = true
        axios
            .get(`${API_URL}nft/sold/recent/${Number}`)
            .then(response => {
              this.RecentlySold = response.data
              this.SpinnerWork = false;
            })
      } else {
        this.SpinnerWork = true
        axios
            .get(`${API_URL}nft/sold/recent/${Number}/${this.contract}`)
            .then(response => {
              this.RecentlySold = response.data;
              this.SpinnerWork = false;
            })
      }

    }

  },
  mounted() {
    this.GetRecentlySoldData(this.GetFirstBatch)
  }

}
</script>




