<template>
  <div class="home DetailArt">
    <div class="Hero">
      <div class="container-fluid">
        <iframe class="BigImg" v-if="thetaAPI" style="background-color: black" :src="animationURL" allowfullscreen/>
        <video class="BigImg" style="background-color: black" v-else-if="animationURL !== ''" :src="animationURL" autoplay loop></video>
        <img v-img v-else class="BigImg" :src="NftImg" alt=""/>

        <div class="Hero__Content">
          <h1>{{ NftName }}<span style="padding-left: 5px" v-if="!/#\d/.test(NftName)">#{{ tokenId }}</span></h1>
          <h6 v-if="editions > 0">Editions {{editions}}</h6>
          <p>{{ NftDescription }}</p>
          <div class="AvatarName">
            <img  :src="$store.state.CreatorsToImg[creator] !== '' ? $store.state.CreatorsToImg[creator] : require('../assets/Img/Avatar.png')" alt=""/>
            <span>{{ creator }}</span>
          </div>
          <div class="Price" v-if="onSale">
            <h6>Price:</h6>
            <h2 class="PriceETH">{{(priceTFuel).toLocaleString('en').replace(',', ' ')}}
              <img src="./../assets/Img/tfuel_token.png" alt="" style="width: 40px; border-radius: 100%; padding: 0px 5px 5px 5px"/>
              (${{(priceDollar).toLocaleString('en').replace(',', ' ')}})</h2>
          </div>
          <button @click="BuyFunction()"  v-if="onSale && !myNFT">Buy</button>
          <button @click="MyNFTs"  v-if="myNFT">My NFTs</button>
          </div>
        </div>
      <div class="container-fluid" v-if="attributes" style="color: #212121; padding-top: 20px !important; display: block !important;">
        <div  v-for="(attribute,n) in attributes" :key="n" style="display: inline-flex">
          <div v-if="attribute.trait_type !== 'style'" style="padding: 10px; border-radius: 10px;
        background: radial-gradient(209.87% 161.91% at 70.19% 90%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
         margin: 10px; display: inline-flex">
            <span>{{attribute.trait_type}} : {{attribute.value}}</span>
          </div>
        </div>
      </div>
    </div>
<!--    <div style="padding: "></div>-->
    <RecentlySoldCmpt style="padding-top: 150px" :contract="contract"/>
<!--    <HistoryCmpt />-->
<!--    <MoreWorksByLukeCmpt />-->
    <Myfooter />
  </div>
</template>

<script>
import Myfooter from "../components/Footer.vue";
// import MoreWorksByLukeCmpt from "../components/MoreWorksByLuke.vue";
// import HistoryCmpt from "../components/History.vue";
import RecentlySoldCmpt from '../components/RecentlySold.vue'
import {ethers} from "ethers";
import axios from "axios";
import API_URL, {ABI_MARKET, ABI_NFT, MARKET_ADDRESS_OLD} from "../../Config";

export default {
  name: "DetailArt",
  data() {
    return {
      NftImg: "",
      NftName: "",
      NftDescription: "",
      ProjectImg: "",
      creator: "",
      price: "",
      priceTFuel: '',
      priceDollar: '',
      editions: '',
      tokenEdition: '',
      animationURL: '',
      thetaAPI: false,
      tokenId: null,
      contract: null,
      onSale: false,
      attributes: null,
      myNFT: false,
      marketAddress: ''
    };
  },
  components: {
    Myfooter,
    RecentlySoldCmpt
    // MoreWorksByLukeCmpt,
    // HistoryCmpt,
  },
  methods: {
    async GetDetailNFt() {
      // console.log(this.$store.state.DeaylArtData);
      // console.log(this.$store.state.DeaylArtData.itemId)

      if(this.$store.state.DeaylArtData === null){
        await this.$router.push("/Explore");
        return;
      } else if(this.$store.state.DeaylArtData.itemId === undefined) {
        // console.log(this.$store.state.DeaylArtData.itemId)
        this.NftImg = this.$store.state.DeaylArtData.imgUrl;
        this.NftName = this.$store.state.DeaylArtData.name;
        this.NftDescription = this.$store.state.DeaylArtData.description;
        this.creator = this.$store.state.DeaylArtData.creator;
        this.editions = 0;
        this.contract = this.$store.state.DeaylArtData.nftContract;
        this.tokenId = this.$store.state.DeaylArtData.tokenId;
        axios
            .get(`${API_URL}projects/${this.contract}`)
            .then(response => {
              // console.log(response.data);
              this.editions = response.data.tokenNumber;
              this.creator = response.data.creator;
              this.ProjectImg = response.data.imgUrl;
            });
        this.myNFT = true;
      } else {
        this.onSale = true;
        if(this.$store.state.DeaylArtData.marketAddress){
          this.marketAddress = this.$store.state.DeaylArtData.marketAddress
        } else{
          this.marketAddress = MARKET_ADDRESS_OLD
        }
        if(this.$store.state.DeaylArtData.seller.toLowerCase() === this.$store.state.UserAddress.toLowerCase()){
          this.myNFT = true;

        }

        this.NftImg = this.$store.state.DeaylArtData.imgUrl;
        this.NftName = this.$store.state.DeaylArtData.name;
        this.NftDescription = this.$store.state.DeaylArtData.projectDescription;
        this.ProjectImg = this.$store.state.DeaylArtData.projectImgUrl;
        this.creator = this.$store.state.DeaylArtData.creator;
        this.editions = this.$store.state.DeaylArtData.tokenNumber;
        this.contract = this.$store.state.DeaylArtData.nftContract;
        this.itemId = this.$store.state.DeaylArtData.itemId;
        this.tokenId = this.$store.state.DeaylArtData.tokenId

        this.price = this.$store.state.DeaylArtData.price;
        this.priceTFuel = (ethers.BigNumber.from(this.price).div(ethers.BigNumber.from("10000000000000000"))).toNumber()/100;
        axios
            .get(`https://explorer.thetatoken.org:8443/api/price/all`)
            .then(response => {
              let data = response.data.body
              for(let i=0; i<data.length; i++){
                if(data[i]._id === "TFUEL"){
                  this.dollar = data[i].price
                }
              }
              this.priceDollar = (this.priceTFuel * this.dollar).toFixed(2)
            })

        // console.log(this.$store.state.DeaylArtData);
      }
      this.provider  = new ethers.providers.Web3Provider(window.ethereum);
      if(this.provider) {
        const contractObject = new ethers.Contract(
            this.contract,
            ABI_NFT,
            this.provider
        );
        // console.log(this.$store.state.DeaylArtData.tokenId);
        if(this.$store.state.DeaylArtData.tokenId === -1){
          this.NftDescription = "This is a Theta Name Service (TNS) domain"
          return
        }
        let nftURI = await contractObject.tokenURI(this.$store.state.DeaylArtData.tokenId);
        if (nftURI.slice(0,4) === 'ipfs') {
          nftURI = 'https://ipfs.io/ipfs/' + nftURI.substring(7)
        }
        // console.log(nftURI)
        axios.get(nftURI).then(response => {
          this.attributes = response.data.attributes;
          this.NftDescription = response.data.description;
          if(response.data.animation_url) {
            if(response.data.theta_api){
              let url  = "https://api.thetavideoapi.com/video/" + response.data.theta_api.videoId;
              // console.log(url)
              axios.get(url).then(() => {
                this.animationURL = "https://player.thetavideoapi.com/video/" + response.data.theta_api.videoId
                // this.animationURL = "https://player.thetavideoapi.com/video/video_xjvrqtkyk1wcf0g2s085i5yc78"
                this.thetaAPI = true
              }).catch(() => {
                this.animationURL= response.data.animation_url
              })
              // this.thetaAPI = true
            } else {
              this.animationURL= response.data.animation_url
            }
          }
          // console.log(this.attributes)
        });
      }
      this.$store.commit("GetDetailArtData", null);
    },
    async BuyFunction() {
      this.loading = true;
      let provider = this.$store.state.Provider;
      const signer = provider.getSigner();


      const contractMarketObject = new ethers.Contract(
          this.marketAddress,
          ABI_MARKET,
          signer
      );
      let overrides = {
        value: this.price,
      };
      try{
        await contractMarketObject.createMarketSale(this.contract, this.itemId, overrides);
      } catch (e) {
        this.loading = false;
      }
      let topic = ethers.utils.id("MarketItemSale(uint256,address,uint256,address,address,string,uint256,bool)");

      let filter = {
        address: this.marketAddress,
        topics: [ topic ]
      }

      provider.on(filter, () => {
        this.loading = false;
        this.$router.push("/MyNFTs");
      });
    },
    MyNFTs() {
      this.$router.go(-1);
    },
    BackFunction() {
      this.$router.go(-1);
    }
  },
  mounted() {
    this.GetDetailNFt();
    window.scrollTo(0, 50);
  },
};
</script>
<style scoped>
.Hero {
  background-image: url("../assets/Img/HeroBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.StayInTheTop__content {
  background-image: url("../assets/Img/SectionBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>



