<template>
  <div class="ExploreArt">
    <div class="TitleAndFilter">
      <h2>Recently Listed</h2>
    </div>
    <div class="ExploreArt__Card">
      <div v-for="(Explore, n) in ExploreArt" :key="n">
        <buyCard :nft="Explore"></buyCard>
      </div>
    </div>
  </div>
</template>

<script>
import buyCard from "@/components/buyNFTCard";
import axios from "axios";
import API_URL from "../../Config";

export default {
  name: "RecentlyListed",
  data() {
    return {
      NumberToExplore: 6,
      ExploreArt: [],
    };
  },
  components: {
    buyCard
  },
  methods: {
    GetRecentlyListed() {
      axios.get(`${API_URL}nft/new/${this.NumberToExplore}/`).then((response) => {
        this.ExploreArt = response.data;
      });
    },
    SwitchToDetailArt(Explore) {
      this.$store.commit("GetDetailArtData", Explore);
      this.$router.push("/DetailArt");
    },
    BuyFunction() {
      console.log("Buy");
    },
  },
  mounted() {
    this.GetRecentlyListed();
  },
};
</script>



