import Vue from 'vue'
import VueLazyload from 'vue-lazyload'
import App from './App.vue'
import router from './router'
import store from './store'
import VueImg from 'v-img';


Vue.use(VueImg);
Vue.use(VueLazyload)

const loadimage = require('./assets/Img/OpenTheta_Logo-transparent_quadratt.png')
const errorimage = require('./assets/Img/OpenTheta_Logo-transparent_quadratt.png')

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: errorimage,
  loading: loadimage,
  attempt: 1
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
