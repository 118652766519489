<template>
  <div class="home">
    <div class="Hero">
        <div class="container-fluid">
            <div class="Hero__Content">
                <h1>Discover, collect, and trade unique NFTs</h1>
                <p>Opentheta.io is the first independent and public marketplace on the Theta blockchain. We are the home of ThetaPunks and many other projects.</p>
                <button><router-link to="/Explore" style="color: black">Explore & Launchpad</router-link></button>
            </div>
            <div class="Hero__Img">
              <video class="NftImg" style="background-color: black" v-if="video" :src="promo.videoUrl" autoplay loop controls muted></video>
                <img v-else :src="ImgUrl" alt="" class="NftImg" >
                <div class="AvatarName">
                    <img :src="ProjectImgUrl != '' ? ProjectImgUrl : require('../assets/Img/Avatar.png')" alt="" class="ProjetImg">
                    <span>{{creator}}</span>
                </div>
                <h3>{{NftName}}</h3>
              <h5 v-if="endDate" style="padding-bottom: 5px; color: white" class="timer" id="countdownHome">{{CountDown}}</h5>
              <h6 v-if="endDate" style="padding-bottom: 5px; color: white" class="timer">current supply: {{currentSupply}}</h6>
                <h4 v-else style="padding-bottom: 20px">{{currentSupply}}/{{maxSupply}}</h4>
                <div class="BuyAndPrice">
                  <button v-if="saleIsActive && !loading" @click="MintFunction" :disabled="loading">
                    Mint
                  </button>
                  <span v-if="loading">loading ...</span>
                  <select v-if="promo && promo.batchMint && saleIsActive" v-model="selectedAmount" style="padding: 10px 5px; border-radius: 5px">
                    <option v-for="i in promo.batchSize" :key="i">{{i}}</option>
                  </select>
                  <h6 v-else-if="!saleIsActive && currentSupply === 0" style="padding: 15px; border-radius: 10px; background: gray">Wrong Network</h6>
                  <h6 v-else-if="!saleIsActive" style="padding: 15px; border-radius: 10px; background: gray">Not Available</h6>
                  <div class="Price">
                    <h6>Price: <span v-if="promo && promo.priceDollar && promo.priceDollar !== 0">$ {{ (promo.priceDollar*selectedAmount).toLocaleString('en').replace(',', ' ')}}</span></h6>
                    <h4> {{currentPrice*selectedAmount}} <img src="./../assets/Img/tfuel_token.png" alt="" style="width: 30px; border-radius: 100%; padding-left: 5px"/></h4>
                  </div>
                </div>
            </div>
        </div>
    </div>
    <AnnouncementBox/>
    <ComingSoon/>
    <ExploreAuctions/>
    <LaunchProjectsHome/>
    <ExploreCreators/>
    <ExploreProjectsCmpt/>
    <RecentlySoldCmpt :contract="'ALL'"/>
<!--    <RecentlyListedCmpt/>-->
<!--    <PopularArtistCmpt/>-->
<!--    <div class="StayInTheTop">-->
<!--      <div class="StayInTheTop__content">-->
<!--          <h1>Stay in the loop on CryptoArt and NFTs</h1>-->
<!--          <p>Subscribe to our newsletter to get notified about upcoming projects and new features.</p>-->
<!--          <div class="Subscribe">-->
<!--                <input type="text" placeholder="Your Email">-->
<!--                <button>Subscribe</button>-->
<!--          </div>-->
<!--      </div>-->

<!--    </div>-->
    <Myfooter/>
  </div>
</template>

<script>
import Myfooter from '../components/Footer.vue'
import RecentlySoldCmpt from '../components/RecentlySold.vue'
import ComingSoon from '../components/ComingSoon.vue'
// import RecentlyListedCmpt from '../components/RecentlyListed.vue'
import LaunchProjectsHome from "@/components/LaunchProjectsHome";
// import promo from '../promotion/promotion.json'
// import PopularArtistCmpt from '../components/PopularArtist.vue'
import ExploreProjectsCmpt from "@/components/ExploreProjects";
import API_URL, {ABI_NFT} from "../../Config";
import {ethers} from 'ethers'
import ExploreCreators from "@/components/ExploreCreators";
import axios from "axios";
import AnnouncementBox from "@/components/AnnouncementBox";
import ExploreAuctions from "@/components/ExploreAuctions";

// import axios from 'axios'
// import API_URL from "../../Config";
// import ethers from 'ethers'
export default {
  name: 'Home',
  data() {      
    return{
      ImgUrl : '',
      currentPrice:'0',
      NftName : '',
      creator : '',
      ProjectImgUrl : '',
      saleIsActive: false,
      maxSupply: 10000,
      currentSupply: 0,
      promo: null,
      video: false,
      endDate: null,
      CountDown: null,
      selectedAmount: 1,
      loading: false
    }
  },
  components: {
    ExploreAuctions,
    AnnouncementBox,
    ExploreCreators,
    Myfooter,
    RecentlySoldCmpt,
    // RecentlyListedCmpt ,
    // PopularArtistCmpt,
    LaunchProjectsHome,
    ExploreProjectsCmpt,
    ComingSoon
  },
  methods:{
    async GetPromotedData(){
      this.loading = true;
      let accounts;
      if (typeof window.ethereum !== 'undefined') {
        accounts = await window.ethereum.request({method: 'eth_requestAccounts'});
      }
      axios
          .get(`${API_URL}static/promotion.json`)
          .then(response => {
            this.promo = response.data
            this.ImgUrl = this.promo.imgUrl
            if(this.promo.video) {
              this.video = this.promo.video
            }
            if(this.promo.endDate) {
              this.endDate = this.promo.endDate
              this.countdown()
            }
            this.NftName = this.promo.name
            this.creator = this.promo.creator
            this.ProjectImgUrl = this.promo.creatorUrl
            this.maxSupply = this.promo.maxSupply;
            let provider = this.$store.state.Provider;
            if (provider) {
              const contractMintObject = new ethers.Contract(
                  this.promo.contract,
                  ABI_NFT,
                  provider
              );
              contractMintObject.totalSupply().then(res => {
                this.currentSupply = res.toNumber();
                if (this.currentSupply < this.maxSupply) {
                  contractMintObject.saleIsActive().then(res => {
                    if(this.CountDown !== "Completed"){
                      this.saleIsActive = res;
                    }
                    if(this.saleIsActive) {
                      if(this.promo.hasDiscount) {
                        contractMintObject.getDiscountedNFTPrice(accounts[0]).then(res => {
                          this.currentPrice = (ethers.BigNumber.from(res).div(ethers.BigNumber.from("1000000000000000000"))).toNumber();
                          this.loading = false;
                        }).catch((error) => {
                          console.log(error);
                          this.loading = false;
                          this.saleIsActive = false
                          // this.loading =false;
                        });
                      } else {
                        contractMintObject.getNFTPrice().then(res => {
                          this.currentPrice = (ethers.BigNumber.from(res).div(ethers.BigNumber.from("1000000000000000000"))).toNumber();
                          this.loading = false;
                        }).catch((error) => {
                          this.loading = false;
                          console.log(error);
                          // this.loading =false;
                        });
                      }
                    } else {
                      this.loading = false;
                    }
                  }).catch((error) => {
                    this.loading = false;
                    console.log(error);
                  });
                } else {
                  this.loading = false;
                  this.saleIsActive = false;
                }
              }).catch((error) => {
                this.loading = false;
                console.log(error);
              });
            } else {
              this.loading = false;
            }
          })

    },
    BuyFunction(){
      console.log("this is buy funtion")
    },
    async MintFunction() {
      console.log("mint", this.promo.contract);
      // let userAddress = this.$store.state.UserAddress;
      let userAddress = '';
      if (typeof window.ethereum !== 'undefined') {
        let accounts = await window.ethereum.request({method: 'eth_requestAccounts'});
        userAddress = accounts[0]
      }
      let provider = this.$store.state.Provider;
      if (provider !== null && userAddress !== '') {
        // console.log('test')
        const signer = provider.getSigner();
        const contractMintObject = new ethers.Contract(
            this.promo.contract,
            ABI_NFT,
            signer
        );
        let price = 0;
        if(this.promo.hasDiscount) {
          price = await contractMintObject.getDiscountedNFTPrice(userAddress);
          console.log("Price", price)
        } else {
          price = await contractMintObject.getNFTPrice();
        }
        let overrides = {
          value: price.mul(this.selectedAmount),
        };
        if(parseInt(this.selectedAmount) === 1) await contractMintObject.safeMint(userAddress, overrides);
        if(parseInt(this.selectedAmount) > 1) await contractMintObject.safeMintQuantity(userAddress, this.selectedAmount, overrides);
        // await contractMintObject.safeMint(userAddress, overrides);
        await setTimeout(() => {
          this.GetPromotedData()
        },5000)
      } else {
        alert("Connect your wallet");
      }
    },
    pad(n) {
      return (n < 10 ? "0" + n : n);
    },
    timer() {
      let endTime = new Date(this.endDate).getTime();
      let startTime = Date.now()
      let seconds = (endTime - startTime)/1000
      // console.log(seconds)
      if(seconds <= 0) {
        this.saleIsActive = false
        clearInterval(this.countdownTimer);
        this.CountDown = "Completed";
      } else {
        let days        = Math.floor(seconds/24/60/60);
        let hoursLeft   = Math.floor((seconds) - (days*86400));
        let hours       = Math.floor(hoursLeft/3600);
        let minutesLeft = Math.floor((hoursLeft) - (hours*3600));
        let minutes     = Math.floor(minutesLeft/60);
        let remainingSeconds = seconds % 60;
        if(days === 0){
          this.CountDown = this.pad(hours) + "h " + this.pad(minutes) + "min " + this.pad(parseInt(remainingSeconds));
          if(hours <= 12 && document.getElementById('countdownHome') !== null){
            document.getElementById('countdownHome').style.color = "#fd3232";
          }
        } else {
          this.CountDown = this.pad(days) + "d " + this.pad(hours) + "h " + this.pad(minutes) + "min " + this.pad(parseInt(remainingSeconds));
        }
      }
      // console.log(endTime, startTime)
    },
    countdown () {
      this.countdownTimer = setInterval(this.timer, 1000);
      this.timer()
    }
  },
  mounted() {
    this.GetPromotedData()
  }

}
</script>
<style scoped>
.Hero{
    background-image:url('../assets/Img/HeroBackground.png');
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
}
.StayInTheTop__content{
      background-image:url('../assets/Img/SectionBackground.png');
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
}
</style>



