<template>
  <div class="Card">
    <img v-lazy="nft.imgUrl" alt="" class="NFTImg" @click="SwitchToDetailArt(nft)" />
    <div class="AvatarName">
      <img
          v-lazy="$store.state.CreatorsToImg[this.nft.creator]"
          alt=""
          style="width: 40px; height: 40px; border-radius: 100%"
      />
      <span>{{ nft.creator }}</span>
    </div>
    <h3 v-if="nft.name.length < 25">{{ nft.name }}<span style="padding-left: 5px" v-if="!/#\d/.test(nft.name) && nft.name.length < 21">#{{ nft.tokenId }}</span></h3>
    <h3 v-else-if="nft.name.length < 28" style="font-size: 18px">{{ nft.name}}</h3>
    <h3 v-else>{{ nft.name.slice(0,25) }}...</h3>
    <div>
      <span style="font-size: 12px">Editions: {{ editions }}</span>
      <span style="font-size: 12px; padding-left: 5px" v-if="rank!== ''">Rank: {{ rank }}</span>
      <span style="font-size: 12px; padding-left: 5px" v-if="!/#\d/.test(nft.name) && nft.name.length > 20">TokenID: {{ nft.tokenId }}</span>
    </div>
    <div class="BuyAndPrice">
      <button v-if="!yourNFT" @click="BuyFunction()">Buy</button>
      <h6 v-if="yourNFT" style="padding: 15px; border-radius: 10px; background: gray">My NFT</h6>
      <div class="Price">
        <h6>Price:</h6>
        <h5>{{ (price).toLocaleString('en').replace(',', ' ').replace(',', ' ')}} <img src="./../assets/Img/tfuel_token.png" alt="" style="width: 25px; border-radius: 100%; padding-left: 3px"/></h5>
      </div>
    </div>
    <Loading :active.sync="loading"
             color="#18E5E7"
             background-color="#000"
             loader="dots"
    ></Loading>
  </div>
</template>

<script>
import { ethers } from 'ethers';
import {ABI_MARKET, MARKET_ADDRESS_OLD} from "../../Config";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ThetaPunksRarity from '../promotion/rarityThetaPunks.json'
// import eventBus from "@/module/eventBus";


export default {
  name: "buyCard",
  props: ['nft'],
  data () {
    return {
      price: '0',
      loading: false,
      yourNFT: false,
      editions: '---',
      rank: '',
      marketAddress: '',
    }
  },
  components: {
    Loading
  },
  methods: {
    SwitchToDetailArt(Explore) {
      this.$store.commit("GetDetailArtData", Explore);
      // eventBus.$emit('showNFTDetails');
      let searchItems = this.$store.state.Search;
      if(searchItems) {
        searchItems["position"] = window.pageYOffset;
        searchItems.use = true
        this.$store.commit('SetSearch', searchItems);
      }
      this.$router.push("/DetailArt");
    },
    async BuyFunction() {
      this.loading = true;
      let provider = this.$store.state.Provider;
      const signer = provider.getSigner();


      const contractMarketObject = new ethers.Contract(
          this.marketAddress,
          ABI_MARKET,
          signer
      );
      let overrides = {
        value: this.nft.price,
      };
      try{
        await contractMarketObject.createMarketSale(this.nft.nftContract, this.nft.itemId, overrides);
      } catch (e) {
        this.loading = false;
      }
      let topic = ethers.utils.id("MarketItemSale(uint256,address,uint256,address,address,string,uint256,bool)");

      let filter = {
        address: this.marketAddress,
        topics: [ topic ]
      }

      provider.on(filter, () => {
        this.loading = false;
        this.$router.push("/MyNFTs");
      });
    },
  },
  mounted() {
    this.price = (ethers.BigNumber.from(this.nft.price).div(ethers.BigNumber.from("10000000000000000"))).toNumber()/100;
    if(this.nft.seller.toLowerCase() === this.$store.state.UserAddress.toLowerCase()) this.yourNFT = true;
    this.editions = this.nft.tokenNumber;
    if(this.nft.marketAddress){
      this.marketAddress = this.nft.marketAddress
    } else {
      this.marketAddress = MARKET_ADDRESS_OLD
    }
    console.log(this.creatorImg)
    // console.log(this.marketAddress)
    if(ThetaPunksRarity[this.nft.name]){
      this.rank =ThetaPunksRarity[this.nft.name];
    }
    // console.log(this.yourNFT);
  }
}
</script>

<style scoped>

</style>
