<template>
  <div class="ExploreArt" style="padding: 0px; margin-top: 100px" v-if="launchProjects.length > 0 || auctionProjects.length > 0">
    <div class="ExploreArt__Card">
      <div class="Card" style="min-width: 90%; background:rgb(45, 45, 45); padding: 15px">
        <h3 style="padding-top: 0px; padding-bottom: 5px; color: #FFFFFF; text-align: center">Ukraine Relief Aid Campaign</h3>
        <p style="color: rgb(34,94,187); text-align: center">This fundraiser aims to help people suffering from the current war in the Ukraine. We condemn any sort of violence. Therefore, all donations go into humanitarian aid.</p>
        <br>
        <p style="color: rgb(34,94,187); text-align: center">To support the people in need, we are launching various NFTs from creators that have launched projects on OpenTheta before. A huge thank you to these generous creators for providing us with their art for free. 100% of the proceeds will be donated.</p>
        <br>
        <p style="color: rgb(249,211,72); text-align: center">The charities we aim to support are the Ukraine Binance Emergency Fund and Unchained. Both of them are closely connected with big NGOs (UNICEF, UNHCR, Project CURE, International Medical Corps).</p>
        <br>
        <p style="color: rgb(249,211,72); text-align: center;">Here <a href="https://www.binance.charity/Ukraine-Emergency-Relief-Fund" target="_blank" style="color: rgb(249,211,72)"><b>Binance</b></a> and here <a href="https://unchain.fund" target="_blank" style="color: rgb(249,211,72)"><b>Unchained</b></a> you can donate to them directly. Alternatively, you can send TFuel to our donation address:</p>
        <p style="color: rgb(249,211,72); text-align: center;">0xB8ad91B47f03B24bEb01C59d369d95056F265FE7</p>
      </div>
    </div>
    <div class="ExploreArt__Card">
      <div v-for="(project, n) in launchProjects" :key="n">
        <LaunchCard :project="project"></LaunchCard>
      </div>
    </div>
    <div class="ExploreArt__Card">
      <div v-for="(auction, n) in auctionProjects" :key="n">
        <AuctionCard :auction="auction"></AuctionCard>
      </div>
    </div>
  </div>
</template>

<script>
import LaunchCard from "@/components/LaunchCard";
import AuctionCard from "@/components/AuctionCard";
import axios from "axios";
import API_URL from "../../Config";

export default {
  name: "AnnouncementBox",
  data() {
    return {
      launchProjects:[],
      auctionProjects: [],
      currentSupply: 0,
    };
  },
  components: {
    LaunchCard,
    AuctionCard
  },
  methods: {
  },
  mounted() {
    // console.log(API_URL);
    axios.get(`${API_URL}static/specialLaunch.json`).then((response) => {
      this.launchProjects = response.data.slice(0,3);
    });
    axios.get(`${API_URL}static/specialAuction.json`).then((response) => {
      this.auctionProjects = response.data;
    });
  },
}
</script>

<style scoped>

</style>