<template>
  <div class="Card">
    <img :src="creator.img != '' ? creator.img : require('../assets/Img/OpenTheta_Logo-transparent_quadratt.png')" alt="" class="NFTImg" @click="SwitchToExplore(creator)"/>
    <h3 style="padding-top: 5px">{{ creator.name }}</h3>
  </div>
</template>

<script>

import API_URL from "../../Config";

export default {
  name: "creatorCard",
  props: ['creator'],
  data () {
    return {
    }
  },
  methods: {
    SwitchToExplore(creator) {
      this.$store.commit('SetExploreData', creator.name);
      this.$router.push("/Explore");
      window.scrollTo(0, 800);
    },
  },

  created() {
    if(this.creator.img !== '' && this.creator.img.slice(0,8) !== "https://"){
      this.creator.img = API_URL + "images/creators/" + this.creator.img;
    }
  }
}
</script>

<style scoped>

</style>